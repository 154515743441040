import * as React from 'react';
import { useRequest } from 'src/utils/RESTful';
import { useLoginUser } from 'src/hooks/useLoginUser';

export type AuthorityTree = {
  [authorityName: string]: boolean | undefined | AuthorityTree,
};
export type AuthorityValue = boolean | undefined | AuthorityTree;

type Menu = {
  id: number,
  parentId: number,
  name: string,
  lang_key: string,
  link: string,
  sort: number,
};

export type Authority = {
  menu: Menu[],
  authority: AuthorityValue,
};

function defaultAuthority(): Authority {
  return {
    menu: [],
    authority: false,
  };
}

const loadingAuthorityActionCache = new Map<string, Promise<Authority>>();
const loadedAuthorityResultCache = new Map<string, Authority>();
export function useLoadAuthority() {
  const request = useRequest(false);
  const loginToken = useLoginUser().token;

  /** 若loadedAuthorityResultCache已存在載入完畢的權限，則預設ready */
  const [ ready, setReady ] = React.useState(loadedAuthorityResultCache.has(loginToken));
  let authority = defaultAuthority();

  /** 若尚未ready，才進行快取設置 */
  if (!ready) {
    /** 檢查是否已經有正在進行的載入動作 */
    const loadingAction = loadingAuthorityActionCache.get(loginToken);
    if (loadingAction) {
      /** 若已存在，則該動作執行完畢後自動將權限結果存入loadedAuthorityResultCache，並設置ready state */
      loadingAction.then((authority) => {
        loadedAuthorityResultCache.set(loginToken, authority);
        setReady(true);

        return authority;
      });
    }
    /** 否則檢查當前是否是登入狀態 */
    else {
      /** 若是登入狀態，則執行載入權限動作，並將動作存入loadingAuthorityActionCache */
      if (loginToken) {
        const loadingAction = request<Authority>({
          url: '/auth/page',
        })
          .then((authority) => {
            loadedAuthorityResultCache.set(loginToken, authority);
            setReady(true);

            return authority;
          });
        loadingAuthorityActionCache.set(loginToken, loadingAction);
      }
      /** 否則自動設置為登入權限與ready state */
      else {
        loadedAuthorityResultCache.set('', defaultAuthority());
        setReady(true);
      }
    }
  }

  if (ready) {
    authority = loadedAuthorityResultCache.get(loginToken || '');
  }

  return {
    ready,
    authority,
  };
}

import * as React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useBasicApiConfig } from 'src/hooks/useBasicApiConfig';
import { useLoginUser, useSetLoginUser } from 'src/hooks/useLoginUser';
import {
  BasicSchema,
  useValidation,
  useRequest,
  useThrottle,
  isThrottleReady,
} from 'src/utils/RESTful';
import {
  notEmpty,
  needBePassword,
  needBeEmail,
  RuleSet,
} from 'src/utils/validations';

interface RawResponse {
  identityNumber: string,
  companyName: string,
  clientNumber: string,
  email: string,
  twoFa: boolean,
  isRoot: boolean,
  contactName: string,
  contactTel: string,
  contactMobile: string,
}

const symbol = Symbol('info/useLoadInfoThrottleOnMount');
export function useLoadInfoThrottleOnMount() {
  const [ ready, setReady ] = React.useState(isThrottleReady(symbol));
  const request = useRequest();
  const setLoginUser = useSetLoginUser();
  const action = React.useCallback(() => {
    return request<RawResponse>({
      url: '/client/info',
    })
      .then((response) => {
        setLoginUser(Object.assign(response, {
          twoFa: response.twoFa ? 'Y' : 'N',
        }));
        setReady(true);

        return response;
      });
  }, [ request, setLoginUser ]);
  const throttle = useThrottle(symbol, action);
  React.useEffect(() => {
    throttle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ready,
    reload: action,
  };
}

const symbol2 = Symbol('info/useCheckTokenThrottleOnMount');
export function useCheckTokenThrottleOnMount() {
  const [ ready, setReady ] = React.useState(isThrottleReady(symbol2));
  const loginUser = useLoginUser();
  const setLoginUser = useSetLoginUser();
  const { i18n } = useTranslation();
  const basicConfig = useBasicApiConfig(i18n.language);
  const axiosConfig = React.useMemo(() => {
    return Object.assign({}, basicConfig, {
      url: '/client/info',
      method: 'get',    
    });
  }, [ basicConfig ]);
  const action = React.useCallback(() => {
    /** 此處直接呼叫axios，因不需要顯示loading，且在API錯誤時不能顯示 */
    return axios.request(axiosConfig)
      .then(() => {
        setReady(true);
      })
      .catch(() => {
        setLoginUser({
          token: '',
        });
      });
  }, [ axiosConfig, setLoginUser ]);
  const throttle = useThrottle(symbol2, action);
  React.useEffect(() => {
    throttle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ready,
    isLogin: Boolean(loginUser?.token),
  };
}

/** create form */
export interface FormSchema extends BasicSchema {
  id: string,
  identityNumber: string,
  companyName: string,
  clientNumber: string,
  email: string,
  twoFa: 'Y' | 'N',
  pass0: string,
  pass1: string,
  pass2: string,
  isRoot: boolean,
}
export function defaultFormValue() {
  return {
    id: '',
    identityNumber: '',
    companyName: '',
    clientNumber: '',
    email: '',
    twoFa: 'Y',
    pass0: '',
    pass1: '',
    pass2: '',
    isRoot: true,
  } as FormSchema;
}

const rules: RuleSet = {};
const passwordRules: RuleSet = {
  pass0: [
    notEmpty,
    needBePassword,
  ],
  pass1: [
    notEmpty,
    needBePassword,
  ],
};
export function useValidate() {
  const { t } = useTranslation();
  const validation = useValidation(passwordRules);

  return useValidation(rules, (data: FormSchema, errors) => {
    if (data.pass0) {
      const passwordErrors = validation(data);
      Object.keys(passwordErrors).forEach((fieldName) => {
        if (errors[fieldName]) return;
        errors[fieldName] = passwordErrors[fieldName];
      });
      if (data.pass1 !== data.pass2) {
        errors['pass2'] = t('console.pageUpsertIAM.confirmPasswordNotMatch');
      }
    }
    if (!data.isRoot) {
      if (data.twoFa === 'Y') {
        const result = needBeEmail(data.email, t);
        if (typeof result === 'string') {
          errors.email = result;
        }
      }
    }
    return errors;
  });
}

export function useApiUpdate() {
  const request = useRequest();

  return React.useCallback((values: FormSchema) => {
    const data = {
      twoFa: values.twoFa === 'Y',
      pass0: values.pass0,
      pass1: values.pass1,
    } as Record<string, unknown>;
    if (!values.isRoot) {
      data.email = values.email;
    }
    return request({
      url: '/client/info',
      method: 'patch',
      data,
    });
  }, [ request ]);
}

import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  Container,
  Grid,
  CircularProgress,
} from '@mui/material';
import { routes } from 'src/utils/routes';
import { useWatchWindowSize } from 'src/hooks/useWatchWindowSize';
import { Header } from './Header';
import { Footer } from './Footer';
import { CookieApprove } from './CookieApprove';
import styles from './portalLayout.scss';

export function PortalLayout() {
  const pageList = React.useMemo(() => {
    const result = [];
    routes.forEach((route) => {
      if (route.layout === 'portal') {
        const id = route.id;
        const path = route.path || `/${id}`;
        const pageFolder = route.folder;
        const PageComponent = React.lazy(() => import(`src/pages/${pageFolder}/Page${id}`));
  
        result.push(
          <Route
            key={id}
            path={path}
            element={
              <React.Suspense fallback={ <Loading /> }>
                <PageComponent />
              </React.Suspense>
            } />
        );
      }
    });
    return result;
  }, []);
  const { height: windowHeight } = useWatchWindowSize();
  const pageContainerStyles = React.useMemo(() => {
    return {
      overflow: 'auto',
      margin: '0 auto 0 auto',
      height: `${windowHeight - 255 - 85}px`,
    };
  }, [ windowHeight ]);

  return (
    <Container
      maxWidth="xl"
      disableGutters
      className={ styles.layout }>
      <Header />
      <div style={ pageContainerStyles }>
        <Routes>
          { pageList }
        </Routes>
      </div>
      <Footer />
      <CookieApprove />
    </Container>
  );
}
export default PortalLayout;

function Loading() {
  return (
    <Grid
      container={ true }
      spacing={ 0 }
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="loading">
      <CircularProgress />
    </Grid>
  );
}
import * as React from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useTranslation } from 'react-i18next';
import { useMemoRouteHref } from 'src/hooks/useNavigate';
import { LanguageButton } from './LanguageButton';
import { Check } from 'src/components/Check';
import MyAccountButton from './MyAccountButton';
const logo = require('src/assets/console/chief-logo.png');

export function Header() {
  const { t } = useTranslation();
  const showButton = false;
  return (
    <Box className="header">
      <AppBar
        className="bar"
        position="fixed"
        elevation={ 0 }>
        <Toolbar disableGutters>
          <a href={ useMemoRouteHref('Dashboard') }>
            <img src={ logo.default } />
          </a>
          <Typography variant="h1">
            <a href={ useMemoRouteHref('Dashboard') }>
              { t('console.header.cloudPortal') }
            </a>
          </Typography>
          <Button
            variant="text"
            size="large"
            href={ t('portal.nav.contactUsUrl') }
            target="_blank">
            { t('console.header.contactUs') }
          </Button>
          {showButton && (
            <Button
            variant="text"
            size="large"
            endIcon={ <ArrowDropDownIcon /> }
            disableElevation>
            { t('console.header.supports') }
          </Button>
          )}
          <LanguageButton />
          <Check authority="myAccount">
            <MyAccountButton />
          </Check>
          <PermIdentityIcon className="user" />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Header;

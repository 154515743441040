// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pWaFsoDIfZNUE8utL76V .MuiDialog-paper{min-width:25rem}.pWaFsoDIfZNUE8utL76V .MuiDialog-paper .MuiDialogTitle-root{padding:.5rem;font-size:1rem}.pWaFsoDIfZNUE8utL76V .MuiDialog-paper button.confirm{color:#ff4c00;border:1px solid #ff4c00}.pWaFsoDIfZNUE8utL76V .MuiDialog-paper button.cancel{color:#999;border:1px solid #999}", "",{"version":3,"sources":["webpack://./components/confirmDialog/confirmDialog.scss","webpack://./utils/utils.scss"],"names":[],"mappings":"AAGE,uCACE,eAAA,CAEA,4DACE,aAAA,CACA,cAAA,CAGF,sDCCF,aAZQ,CAaR,wBAAA,CDEE,qDCHF,UAXU,CAYV,qBAAA","sourcesContent":["@import 'src/utils/utils.scss';\n\n:local(.component) {\n  .MuiDialog-paper {\n    min-width: 25rem;\n\n    .MuiDialogTitle-root {\n      padding: 0.5rem;\n      font-size: 1rem;\n    }\n\n    button.confirm {\n      @include button($primary);\n    }\n\n    button.cancel {\n      @include button($secondary);\n    }\n  }\n}\n","$primary: #ff4c00;\n$secondary: #999;\n$info: #59c1b3;\n$warn: #ffa726;\n$error: #f00;\n$fine: #0a0;\n$fontColor: #333;\n$fontColor2: #fff;\n$titleBgColor: #ddd;\n$titleBgColor2: #eee;\n\n@mixin button($color) {\n  color: $color;\n  border: 1px solid $color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "pWaFsoDIfZNUE8utL76V"
};
export default ___CSS_LOADER_EXPORT___;

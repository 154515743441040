import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useCheckTokenThrottleOnMount } from 'src/data/user/info';
import { useLogoutUser } from 'src/hooks/useLoginUser';
import { useRequest } from 'src/utils/RESTful';
import { useMemoRouteHref, getRouteHref } from 'src/hooks/useNavigate';
import { useLoadAllThrottleOnMount } from 'src/data/portal/products';

export function NavSmall() {
  const [ isMenuOpen, setIsMenuOpen ] = React.useState(false);
  const onClickDropdownBtn = React.useCallback(() => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  }, [ setIsMenuOpen ]);

  /** 網址變動時自動折疊menu */
  const location = useLocation();
  React.useEffect(() => {
    setIsMenuOpen(false);
  }, [ location ]);

  return (
    <div className="smallNav">
      <Button
        className="dropdown"
        onClick={ onClickDropdownBtn }>
        { isMenuOpen ? <CloseIcon /> : <MenuIcon /> }
      </Button>
      { isMenuOpen ? <SmallNavMenu /> : null }
    </div>
  );
}

function SmallNavMenu() {
  const { t } = useTranslation();
  const { ready, isLogin } = useCheckTokenThrottleOnMount();
  const request = useRequest();
  const logoutUser = useLogoutUser();
  const onClickLogout = React.useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await request({
      url: '/users/logout',
      method: 'post',
      headers: {
        'X-LOG-CODE': '10008',
      },
    });
    logoutUser();
  }, [ request, logoutUser ]);
  const loginHref = useMemoRouteHref('Login');
  const loginoutButton = React.useMemo(() => {
    if (ready && isLogin) {
      return (
        <a href="#" onClick={ onClickLogout }>
          { t('portal.nav.logout') }
        </a>
      );
    }
    else {
      return (
        <a href={ loginHref }>
          { t('portal.nav.login') }
        </a>
      );
    }
  }, [ t, ready, isLogin, onClickLogout, loginHref ]);
  const registerQueryHref = useMemoRouteHref('RegisterQuery');
  const registerHref = useMemoRouteHref('Register');
  const lastestNewsHref = useMemoRouteHref('LastestNews');

  return (
    <div className="menu">
      <a
        href={ t('portal.nav.contactUsUrl') }
        target="_blank"
        rel="noreferrer">
        { t('portal.nav.contactUs') }
      </a>
      <LanguageButton />
      { loginoutButton }
      <a href={ registerQueryHref }>
        { t('portal.nav.registerQuery') }
      </a>
      <a
        className="register"
        href={ registerHref }>
        { t('portal.nav.createAccount') }
      </a>
      <a href={ lastestNewsHref }>
        { t('portal.nav.lastestNews') }
      </a>
      <ProductButton />
      <a
        href={ API_URL_ROOT + 'home/solution' }
        target="_blank"
        rel="noreferrer">
        { t('portal.nav.solution') }
      </a>
      <a
        href={ API_URL_ROOT + 'home/pricing' }
        target="_blank"
        rel="noreferrer">
        { t('portal.nav.pricing') }
      </a>
      <a
        href={ API_URL_ROOT + 'home/download' }
        target="_blank"
        rel="noreferrer">
        { t('portal.nav.documentDownload') }
      </a>
    </div>
  );
}

const languageTextHash = {
  'zh-TW': '中文(繁體)',
  'en': 'English',
};

function LanguageButton() {
  const { i18n } = useTranslation();
  const [ isExpandMenu, setIsExpandMenu ] = React.useState(false);
  const onClickBtn = React.useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsExpandMenu((isExpandMenu) => {
      return !isExpandMenu;
    });
  }, [ setIsExpandMenu ]);
  const changeLanguage = React.useCallback((language: keyof typeof languageTextHash) => {
    localStorage.setItem('useLanguage', language);
    i18n.changeLanguage(language);
    setIsExpandMenu(false);
  }, [ i18n, setIsExpandMenu ]);
  const menuNode = React.useMemo(() => {
    if (isExpandMenu) {
      return (
        <div>
          <Link
            onClick={ () => changeLanguage('zh-TW') }
            sx={{
              backgroundColor() {
                return i18n.language === 'zh-TW' ? '#fb0' : '#ddd';
              },
              '&:hover': {
                backgroundColor() {
                  return i18n.language === 'zh-TW' ? '#fb0' : '#fd0';
                },
              },
            }}>
            { languageTextHash['zh-TW'] }
          </Link>
          <Link
            onClick={ () => changeLanguage('en') }
            sx={{
              backgroundColor() {
                return i18n.language === 'en' ? '#fb0' : '#ddd';
              },
              '&:hover': {
                backgroundColor() {
                  return i18n.language === 'en' ? '#fb0' : '#fd0';
                },
              },
            }}>
            { languageTextHash['en'] }
          </Link>
        </div>
      );
    }

    return null;
  }, [ isExpandMenu, changeLanguage, i18n.language ]);

  return (
    <>
      <Button
        href="#"
        endIcon={ <ArrowDropDownIcon /> }
        onClick={ onClickBtn }>
        { languageTextHash[i18n.language] }
      </Button>
      { menuNode }
    </>
  );
}

function ProductButton() {
  const { t, i18n } = useTranslation();
  const [ isExpandMenu, setIsExpandMenu ] = React.useState(false);
  const onClickBtn = React.useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsExpandMenu((isExpandMenu) => {
      return !isExpandMenu;
    });
  }, [ setIsExpandMenu ]);
  const productList = useLoadAllThrottleOnMount();
  const menuNode = React.useMemo(() => {
    if (isExpandMenu) {
      const resultList = productList.map((productData) => {
        const href = getRouteHref('Product', {
          id: String(productData.id),
          view: 'overview',
        });

        return (
          <a
            key={ productData.id }
            href={ href }
            className="subMenuLink">
            { i18n.language === 'en' ? productData.productNameE : productData.productNameC }
          </a>
        );
      });
      /** https://redmine.yesee.com.tw/issues/5319#note-11 */
      resultList.push(
        <a
          key="extra1"
          href="https://chiefbmc.chiefappc.com/"
          className="subMenuLink"
          target="_blank"
          rel="noreferrer">
          { t('portal.nav.productExtra1') }
        </a>,
        <a
          key="extra2"
          href="https://ccis2.chiefappc.com/"
          className="subMenuLink"
          target="_blank"
          rel="noreferrer">
          { t('portal.nav.productExtra2') }
        </a>,
      );

      return resultList;
    }

    return null;
  }, [ isExpandMenu, productList, i18n.language, t ]);

  return (
    <>
      <Button
        href="#"
        endIcon={ <ArrowDropDownIcon /> }
        onClick={ onClickBtn }>
        { t('portal.nav.products') }
      </Button>
      { menuNode }
    </>
  );
}

export interface RouteSetting {
  id: string,
  folder: string,
  path: string,
  layout?: 'portal' | 'console',
  permission?: string,
  apiLogCode?: string,
}
export const routes: RouteSetting[] = [
  {
    id: 'Home',
    folder: 'portal/Home',
    path: '/',
    layout: 'portal',
  },
  {
    id: 'Login',
    folder: 'portal/Login',
    path: '/login',
    layout: 'portal',
    apiLogCode: '10001',
  },
  {
    id: 'ForgetPassword',
    folder: 'portal/Login',
    path: '/forgetPassword',
    layout: 'portal',
    apiLogCode: '10002',
  },
  {
    id: 'ForgetPasswordDone',
    folder: 'portal/Login',
    path: '/forgetPasswordDone/:email',
    layout: 'portal',
    apiLogCode: '10002',
  },
  {
    id: 'Register',
    folder: 'portal/Register',
    path: '/register',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'RegisterDone',
    folder: 'portal/Register',
    path: '/registerDone/:email',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'RegisterInfoInput',
    folder: 'portal/RegisterInfoInput',
    path: '/registerInfoInput/:guid',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'RegisterInfoDone',
    folder: 'portal/RegisterInfoInput',
    path: '/registerInfoDone',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'RegisterQuery',
    folder: 'portal/RegisterQuery',
    path: '/registerQuery',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'RegisterQueryDone',
    folder: 'portal/RegisterQuery',
    path: '/registerQueryDone/:email',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'RegisterQueryResult',
    folder: 'portal/RegisterQuery',
    path: '/registerQueryResult/:guid',
    layout: 'portal',
    apiLogCode: '10003',
  },
  {
    id: 'WebMap',
    folder: 'portal/WebMap',
    path: '/webMap',
    layout: 'portal',
  },
  {
    id: 'Product',
    folder: 'portal/Product',
    path: '/product/:id/:view',
    layout: 'portal',
  },
  {
    id: 'LastestNews',
    folder: 'portal/LastestNews',
    path: '/lastestNews',
    layout: 'portal',
  },
  {
    id: 'News',
    folder: 'portal/News',
    path: '/news/:id',
    layout: 'portal',
  },
  {
    id: 'PrivacyRightDeclare',
    folder: 'portal/PrivacyRightDeclare',
    path: '/privacyRightDeclare',
    layout: 'portal',
  },
  {
    id: 'PersonalProfileProtect',
    folder: 'portal/PersonalProfileProtect',
    path: '/personalProfileProtect',
    layout: 'portal',
  },
  {
    id: 'CookiePolicy',
    folder: 'portal/CookiePolicy',
    path: '/cookiePolicy',
    layout: 'portal',
  },
  {
    id: 'FraudPrevent',
    folder: 'portal/FraudPrevent',
    path: '/fraudPrevent',
    layout: 'portal',
  },
  {
    id: 'CopyrightDeclare',
    folder: 'portal/CopyrightDeclare',
    path: '/copyrightDeclare',
    layout: 'portal',
  },
  {
    id: 'CloudDeclare',
    folder: 'portal/CloudDeclare',
    path: '/cloudDeclare',
    layout: 'portal',
  },
  {
    id: 'ResetPassword',
    folder: 'portal/ResetPassword',
    path: '/resetPassword/:guid',
    layout: 'portal',
    apiLogCode: '10002',
  },
  {
    id: 'ResetPasswordDone',
    folder: 'portal/ResetPassword',
    path: '/resetPasswordDone',
    layout: 'portal',
    apiLogCode: '10002',
  },
  {
    id: 'SetPassword',
    folder: 'portal/SetPassword',
    path: '/setPassword/:guid',
    layout: 'portal',
    apiLogCode: '10004',
  },
  {
    id: 'SetPasswordDone',
    folder: 'portal/SetPassword',
    path: '/setPasswordDone',
    layout: 'portal',
    apiLogCode: '10004',
  },
  {
    id: 'MyInfo',
    folder: 'console/MyAccount',
    path: '/myInfo',
    apiLogCode: '10005',
  },
  {
    id: 'MyTicket',
    folder: 'console/MyAccount',
    path: '/myTicket',
    apiLogCode: '10007',
  },
  {
    id: 'OrderManage',
    folder: 'console/OrderManage',
    path: '/orderManage',
  },
  {
    id: 'OrderCreate',
    folder: 'console/OrderManage',
    path: '/order/create',
  },
  {
    id: 'CreateTicket',
    folder: 'console/MyAccount',
    path: '/createTicket',
    apiLogCode: '10007',
  },
  {
    id: 'IAMManage',
    folder: 'console/IAMManage',
    path: '/iam',
    apiLogCode: '10008',
  },
  {
    id: 'CreateIAM',
    folder: 'console/IAMManage',
    path: '/createIAM',
    apiLogCode: '10008',
  },
  {
    id: 'EditIAM',
    folder: 'console/IAMManage',
    path: '/editIAM/:id',
    apiLogCode: '10008',
  },
  {
    id: 'Dashboard',
    folder: 'console/Dashboard',
    path: '/dashboard',
    apiLogCode: '10009',
  },
  {
    id: 'VMSetting',
    folder: 'console/VMSetting',
    path: '/vmSetting',
    apiLogCode: '10010',
  },
  {
    id: 'CreateVM',
    folder: 'console/VMSetting',
    path: '/createVM',
    apiLogCode: '10010',
  },
  {
    id: 'DiskList',
    folder: 'console/DiskList',
    path: '/diskList',
    apiLogCode: '10011',
  },
  {
    id: 'CreateDisk',
    folder: 'console/DiskList',
    path: '/createDisk',
    apiLogCode: '10011',
  },
  {
    id: 'Snapshot',
    folder: 'console/Snapshot',
    path: '/snapshot',
    apiLogCode: '10012',
  },
  {
    id: 'CreateSnapshot',
    folder: 'console/Snapshot',
    path: '/createSnapshot',
    apiLogCode: '10012',
  },
  {
    id: 'CreateSnapshotToNewVM',
    folder: 'console/Snapshot',
    path: '/createSnapshotToNewVM',
    apiLogCode: '10012',
  },
  {
    id: 'SnapshotRule',
    folder: 'console/SnapshotRule',
    path: '/snapshotRule',
    apiLogCode: '10012',
  },
  {
    id: 'CreateSnapshotRule',
    folder: 'console/SnapshotRule',
    path: '/createSnapshotRule',
    apiLogCode: '10012',
  },
  {
    id: 'EditSnapshotRule',
    folder: 'console/SnapshotRule',
    path: '/editSnapshotRule/:id',
    apiLogCode: '10012',
  },
  {
    id: 'VNI',
    folder: 'console/VNI',
    path: '/vni',
    apiLogCode: '10013',
  },
  {
    id: 'Firewall',
    folder: 'console/Firewall',
    path: '/firewall',
    apiLogCode: '10014',
  },
  {
    id: 'CreateFirewall',
    folder: 'console/Firewall',
    path: '/createFirewall',
    apiLogCode: '10014',
  },
  {
    id: 'CreateFirewallByVpcId',
    folder: 'console/Firewall',
    path: '/createFirewall/:vpcId',
    apiLogCode: '10014',
  },
  {
    id: 'EditFirewall',
    folder: 'console/Firewall',
    path: '/editFirewall/:id',
    apiLogCode: '10014',
  },
  {
    id: 'Balancer',
    folder: 'console/Balancer',
    path: '/balancer',
    apiLogCode: '10015',
  },
  {
    id: 'CreateBalancer',
    folder: 'console/Balancer',
    path: '/createBalancer',
    apiLogCode: '10015',
  },
  {
    id: 'EditBalancer',
    folder: 'console/Balancer',
    path: '/editBalancer/:id',
    apiLogCode: '10015',
  },
  {
    id: 'OrderHistory',
    folder: 'console/OrderHistory',
    path: '/orderHistory',
    apiLogCode: '10016',
  },
  {
    id: 'PurchaseCCIS',
    folder: 'console/PurchaseCCIS',
    path: '/purchaseCCIS',
    apiLogCode: '10017',
  },
  {
    id: 'Bucket',
    folder: 'console/Bucket',
    path: '/bucket',
    apiLogCode: '10018',
  },
  {
    id: 'UsageReport',
    folder: 'console/UsageReport',
    path: '/usageReport',
    apiLogCode: '10025',
  },
  {
    id: 'VPCNetList',
    folder: 'console/VpcNets',
    path: '/vpcNets',
    apiLogCode: '10019',
  },
  {
    id: 'CreateVPCNet',
    folder: 'console/VpcNets',
    path: '/createVPCNets',
    apiLogCode: '10019',
  },
  {
    id: 'EditVPCNet',
    folder: 'console/VpcNets',
    path: '/editVPCNets/:netId',
    apiLogCode: '10019',
  },
  {
    id: 'EditNetRoute',
    folder: 'console/VpcNets',
    path: '/editNetRoute/:netId',
    apiLogCode: '10019',
  },
  {
    id: 'CCXList',
    folder: 'console/CCX',
    path: '/ccxList',
    apiLogCode: '10020',
  },
  {
    id: 'CreateCCX',
    folder: 'console/CCX',
    path: '/createCCX',
    apiLogCode: '10020',
  },
  {
    id: 'Alarm',
    folder: 'console/Alarm',
    path: '/alarm',
    apiLogCode: '10022',
  },
  {
    id: 'AlarmCreate',
    folder: 'console/Alarm',
    path: '/alarm/create',
    apiLogCode: '10022',
  },
  {
    id: 'AlarmEdit',
    folder: 'console/Alarm',
    path: '/alarm/edit/:id',
    apiLogCode: '10022',
  },
  {
    id: 'ExCloud',
    folder: 'console/ExCloud',
    path: '/exCloud',
    apiLogCode: '10023',
  },
  {
    id: 'CreateExCloud',
    folder: 'console/ExCloud',
    path: '/createExCloud',
    apiLogCode: '10023',
  },
  {
    id: 'SingleVM',
    folder: 'console/SingleVM',
    path: '/singleVM',
    apiLogCode: '10021',
  },
  {
    id: 'SingleVMById',
    folder: 'console/SingleVM',
    path: '/singleVM/:vmId',
    apiLogCode: '10021',
  },
  {
    id: 'TotalUsage',
    folder: 'console/TotalUsage',
    path: '/totalUsage',
    apiLogCode: '10021',
  },
  {
    id: 'Valuation',
    folder: 'console/Valuation',
    path: '/valuation',
  },
  {
    id: 'Doing',
    folder: 'console',
    path: '/doing',
  },
];
export default routes;

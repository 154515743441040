import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  Unstable_Grid2 as Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import { routes } from 'src/utils/routes';

interface PagesProps {
  height: number;
}
export function Pages(props: PagesProps) {
  const mainStyle = React.useMemo(() => {
    return {
      height: `${props.height}px`,
    };
  }, [props.height]);

  const pageList = React.useMemo(() => {
    const result = [];

    routes.forEach((route) => {
      if (route.layout !== 'portal') {
        const id = route.id;
        const path = route.path || `/${id}`;
        const pageFolder = route.folder;
        const PageComponent = React.lazy(() => import(`src/pages/${pageFolder}/Page${id}`));
  
        result.push(
          <Route
            key={id}
            path={path}
            element={
              <React.Suspense fallback={ <Loading /> }>
                <PageComponent />
              </React.Suspense>
            } />
        );
      }
    });

    return result;
  }, []);

  return (
    <Box
      component="main"
      className="pages"
      style={mainStyle}>
      <Routes>
        { pageList }
      </Routes>
    </Box>
  );
}
export default Pages;

function Loading() {
  return (
    <Grid
      container={ true }
      spacing={ 0 }
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="loading">
      <CircularProgress />
    </Grid>
  );
}

import * as React from 'react';
import { useCheckAuthorityResult } from 'src/hooks/useLoginUser';

type Props = React.PropsWithChildren<{
  authority: string,
}>;
export function Check(props: Props) {
  const checkResult = useCheckAuthorityResult(props.authority);
  if (!checkResult) {
    return null;
  }

  return (
    <>
      { props.children }
    </>
  );
}
export default Check;

import * as React from 'react';
import { AxiosRequestConfig } from 'axios';
import { useTranslation } from 'react-i18next';
import { useCurrentRoute } from 'src/hooks/useNavigate';
import { useLoginUser } from 'src/hooks/useLoginUser';

export function useBasicApiConfig(language?: string): AxiosRequestConfig {
  const { i18n } = useTranslation();
  const i18nLanguage = i18n.language;
  const loginUser = useLoginUser();
  const authorizationToken = loginUser.token;
  const currentRoute = useCurrentRoute();

  return React.useMemo(() => {
    const headers: Record<string, string> = {
      'Accept-Language': language || i18nLanguage,
    };
    if (currentRoute.apiLogCode) {
      headers['X-LOG-CODE'] = currentRoute.apiLogCode;
    }
    if (authorizationToken) {
      headers.Authorization = `Bearer ${authorizationToken}`;
    }

    return Object.assign({}, {
      baseURL: API_URL_ROOT,
      headers,
      displayLoading: true,
      autoNotify: true,
    });
  }, [ language, i18nLanguage, currentRoute.apiLogCode, authorizationToken ]);
}
export default useBasicApiConfig;

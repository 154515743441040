import * as React from 'react';
import { useCurrentRoute } from 'src/hooks/useNavigate';
import { PortalLayout } from './portal/PortalLayout';
import { ConsoleLayout } from './console/ConsoleLayout';

export function Layout() {
  const route = useCurrentRoute();
  const UseLayout = route.layout === 'portal' ? PortalLayout : ConsoleLayout;

  return <UseLayout />;
}
export default Layout;

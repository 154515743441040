import * as React from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { RESTfulConfig, BasicSchema, useReadAll, useRequest, useThrottle } from 'src/utils/RESTful';

export interface ReadAllSchema extends BasicSchema {
  id: string,
  productNameC: string,
  productNameE: string,
}

export const products = new RESTfulConfig({
  baseUrl: '/productManagement/resourceTypeManagement/modifyResourceType',
  default() {
    return {
      id: '',
      productNameC: '',
      productNameE: '',
    } as ReadAllSchema;
  },
});
export default products;

/** actions */
const productListGlobalState = hookstate<ReadAllSchema[]>([]);
const symbol = Symbol('products/useLoadAllThrottleOnMount');
export function useLoadAllThrottleOnMount() {
  const readAll = useReadAll(products);
  const state = useHookstate(productListGlobalState);
  const action = () => {
    return readAll({}, state.set);
  };
  const throttleLoad = useThrottle(symbol, action);
  React.useEffect(() => {
    throttleLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.get();
}

export interface ReadOneSchema extends BasicSchema {
  id: string,
  productNameC: string,
  productNameE: string,
  overviewC: string,
  overviewE: string,
  overviewImage: string,
  functionC: string,
  functionE: string,
  functionImage: string,
  priceC: string,
  priceE: string,
  priceImage: string,
  status: string,
}
const symbol2 = Symbol('products/useLoadAllThrottleOnMount');
export function useLoadOneThrottleOnMount(id: string) {
  const [ result, setResult ] = React.useState<ReadOneSchema | null>(null);
  const request = useRequest();
  const action = (id: string) => {
    const axiosConfig = {
      url: `${products.baseUrl}/${id}`,
      method: 'get',
      params: {
        t: Date.now(),
      },
    };

    return request<ReadOneSchema>(axiosConfig)
      .then((response) => {
        setResult({
          ...response,
          id: String(response.id),
        });
      });
  };
  const throttleLoad = useThrottle(symbol2, action);
  React.useEffect(() => {
    throttleLoad(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ id ]);

  return result;
}

import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type ResizeObserverCallback = (arg: DOMRectReadOnly) => void;
const callbackMap = new WeakMap<Element, ResizeObserverCallback>();
const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
  for (const entry of entries) {
    const callback = callbackMap.get(entry.target);
    if (typeof callback === 'function') {
      callback(entry.contentRect);
    }
  }
});

export function useResizeObserver(ref: React.MutableRefObject<Element>, callback: ResizeObserverCallback) {
  React.useEffect(() => {
    const watchElement = ref.current;
    if (watchElement) {
      callbackMap.set(watchElement, callback);
      resizeObserver.observe(watchElement);
  
      return () => {
        resizeObserver.unobserve(watchElement);
      };
    }
  }, [ callback, ref ]);
}
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Unstable_Grid2 as Grid,
  Typography,
  Divider,
  Link,
} from '@mui/material';
import { useMemoRouteHref } from 'src/hooks/useNavigate';
const imgSrcLogo = require('src/assets/portal/footer-chief-logo.svg').default;
const imgSrcFacebook = require('src/assets/portal/footer-facebook.svg').default;
const imgSrcYoutube = require('src/assets/portal/footer-youtube.svg').default;
const imgSrcLine = require('src/assets/portal/footer-line.png').default;
const imgSrcLinkedIn = require('src/assets/portal/footer-linkedIn.png').default;

export function Footer() {
  const { t, i18n } = useTranslation();
  const fraudPreventHref = useMemoRouteHref('FraudPrevent');

  return (
    <Box className="footer">
      <Grid
        container
        spacing={ 0 }>
        <Grid
          xs={ 12 }
          sm={ 4 }
          lg="auto"
          sx={{
            textAlign: {
              xs: 'center',
              sm: 'left',
            },
          }}>
          <img src={ imgSrcLogo } />
        </Grid>
        <Grid
          xs={ 12 }
          sm={ 8 }
          lg
          sx={{
            textAlign: {
              xs: 'center',
              sm: 'right',
              lg: 'center',
            },
          }}>
          <Typography className="address">
            { t('portal.footer.address') }
          </Typography>
        </Grid>
        <Grid
          xs={ 12 }
          lg
          sx={{
            textAlign: {
              xs: 'center',
              sm: 'right',
            },
          }}>
          <Typography className="telephone">
            { t('portal.footer.telephone1') }
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            { t('portal.footer.telephone2') }
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <div className="nav">
        <NavLink
          href={ useMemoRouteHref('WebMap') }
          text={ t('portal.nav.webMap') } />
        <NavLink
          href={ useMemoRouteHref('PrivacyRightDeclare') }
          text={ t('portal.nav.privacyRightDeclare') } />
        <NavLink
          href={ useMemoRouteHref('PersonalProfileProtect') }
          text={ t('portal.nav.personalProfileProtect') } />
        <NavLink
          href={ useMemoRouteHref('CookiePolicy') }
          text={ t('portal.nav.cookiePolicy') } />
        {
          i18n.language !== 'en' ? (
            <NavLink
              href={ fraudPreventHref }
              text={ t('portal.nav.fraudPrevent') } />
          ) : null
        }
        <NavLink
          href={ useMemoRouteHref('CopyrightDeclare') }
          text={ t('portal.nav.copyrightDeclare') } />
        <NavLink
          href="https://www.chief.com.tw/wp-content/uploads/2023/01/IS-A-001-%E8%B3%87%E8%A8%8A%E5%AE%89%E5%85%A8%E6%94%BF%E7%AD%96.pdf"
          text={ t('portal.nav.securityPolicy') }
          open />
        <NavLink
          href={ useMemoRouteHref('CloudDeclare') }
          text={ t('portal.nav.cloudDeclare') } />
      </div>
      <Typography className="copyright">
        { t('portal.footer.copyright') }
      </Typography>
      <Box className="iconLinks">
        <Link
          href="https://www.facebook.com/chieftelecom"
          target="_blank"
          rel="noopener"
          underline="none"
          sx={{ mx: 3 }}>
          <img
            src={ imgSrcFacebook }
            title={ t('portal.footer.facebook') } />
        </Link>
        <Link
          href="https://www.youtube.com/@chieftelecom9963"
          target="_blank"
          rel="noopener"
          underline="none"
          sx={{ mx: 3 }}>
          <img
            src={imgSrcYoutube}
            title={ t('portal.footer.youtube') } />
        </Link>
        <Link
          href="https://page.line.me/chieftelecom"
          target="_blank"
          rel="noopener"
          underline="none"
          sx={{ mx: 3 }}>
          <img
            style={{ width: '40px' }}
            src={imgSrcLine}
            title={ t('portal.footer.line') } />
        </Link>
        <Link
          href="https://www.linkedin.com/company/chieftelecom"
          target="_blank"
          rel="noopener"
          underline="none"
          sx={{ mx: 3 }}>
          <img
            style={{ width: '40px' }}
            src={imgSrcLinkedIn}
            title={ t('portal.footer.linkedIn') } />
        </Link>
      </Box>
    </Box>
  );
}
export default Footer;

interface NavLinkProps {
  text: string,
  href: string,
  open?: boolean,
}
function NavLink(props: NavLinkProps) {
  return (
    <a
      href={ props.href }
      target={ props.open ? '_blank' : '_top' }
      rel="noreferrer">
      { props.text }
    </a>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { Check } from 'src/components/Check';
import { useCurrentRoute, useNavigate } from 'src/hooks/useNavigate';
import { useLoginUser, useLogoutUser } from 'src/hooks/useLoginUser';
import { useRequest } from 'src/utils/RESTful';

const menuItems = [
  { name: 'myInfo', routeName: 'MyInfo' },
  { name: 'myTicket', routeName: 'MyTicket' },
];

export function MyAccountButton() {
  const currentRoute = useCurrentRoute();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonRef = React.useRef(null);
  const [ isShowMenu, setIsShowMenu ] = React.useState(false);
  const showMenu = () => {
    setIsShowMenu(true);
  };
  const hideMenu = () => {
    setIsShowMenu(false);
  };

  const menuItemList = menuItems.map(item => {
    const onClick = () => {
      navigate(item.routeName);
      hideMenu();
    };
    return (
      <MenuItem
        key={ item.name }
        sx={{
          background() {
            return currentRoute.id === item.routeName ? '#ddd' : '#fff';
          },
          '&:hover': {
            background: '#aaa',
          },
        }}
        onClick={ onClick }
        divider>
        { t('console.header.' + item.name) }
      </MenuItem>
    );
  });
  menuItemList.push(
    <Check
      key="OrderManage"
      authority="myAccount/orderManage">
      <MenuItem
        sx={{
          background() {
            return currentRoute.id === 'OrderManage' ? '#ddd' : '#fff';
          },
          '&:hover': {
            background: '#aaa',
          },
        }}
        onClick={ () => navigate('OrderManage') }
        divider>
        { t('console.header.orderManage') }
      </MenuItem>
    </Check>
  );
  const request = useRequest();
  const logoutUser = useLogoutUser();
  const onClickLogout = React.useCallback(async () => {
    await request({
      url: '/users/logout',
      method: 'post',
      headers: {
        'X-LOG-CODE': '10008',
      },
    });
    logoutUser();
    navigate('Home');
  }, [ request, logoutUser, navigate ]);
  const currentUser = useLoginUser();

  return (
    <>
      <Button
        ref={ buttonRef }
        variant="text"
        size="large"
        endIcon={ <ArrowDropDownIcon /> }
        onClick={showMenu}>
        { t('console.header.myAccount') }
      </Button>
      <Menu
        anchorEl={ buttonRef.current }
        open={ isShowMenu }
        onClose={ hideMenu }
        PaperProps= {{
          elevation: 0,
          sx: {
            py: 0,
            borderRadius: 0,
          },
        }}>
        <MenuItem divider>
          { t('console.header.clientNumber') }
          { currentUser.clientNumber }
        </MenuItem>
        { menuItemList }
        <MenuItem
          sx={{
            background: '#fff',
            '&:hover': {
              background: '#aaa',
            },
          }}
          onClick={ onClickLogout }
          divider>
          { t('console.header.logout') }
        </MenuItem>
      </Menu>
    </>
  );
}
export default MyAccountButton;

import { TFunction } from 'react-i18next';

export interface ValidateableData {
  [fieldName: string]: unknown,
}
export type Errors = Record<string, string>;
export type Validate = (data: ValidateableData) => Errors;
/** 前端驗證用規則方法：輸入需驗證的資料與i18n翻譯器，回傳錯誤訊息或true。 */
export type Rule = (value: unknown, t: TFunction<'translation', undefined>) => string | true;
/** 前端驗證用規則組，鍵值為欄位名稱。 */
export type RuleSet = Record<string, Rule[]>;
export type ExtraValidate = (data: ValidateableData, errors: Errors) => Errors;

/** 以下是泛用驗證器 */
export function DontValidate() {
  return {};
}
export function validate(fieldName: keyof ValidateableData, data: ValidateableData, errors: Errors, rule: Rule, t: TFunction<'translation', undefined>) {
  const result = rule(data[fieldName], t);
  if (result !== true) {
    errors[fieldName] = result;
  }
}

/** 驗證「不可為空」規則 */
export const notEmpty: Rule = function(value, t) {
  if (typeof value === 'object' && value) {
    return (value instanceof File) || Boolean(Object.keys(value).length) || t('validation.notEmpty');
  }
  else if (typeof value === 'number') {
    return true;
  }

  return Boolean(value) || t('validation.notEmpty');
};

/** 驗證「若不為空，則必須符合OO規則」規則 */
export const canBeEmptyOr: (validation: Rule) => Rule = (validation) => {
  return (value, t) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return true;
    }

    return validation(value, t);
  };
};

const personIdBaseRegExp = /^[A-Z][1-2]{1}[0-9]{8}$/;
/** 驗證是否符合身份證號規則 */
export const needBePersonId: Rule = function(value, t) {
  if (typeof value === 'string') {
    if (personIdBaseRegExp.test(value)) {
      const sexChar = value.slice(1, 2);
      if (sexChar !== '1' && sexChar !== '2') {
        return t('validation.needBePersonId');
      }
      const alphaCharCode = value.slice(0, 1).charCodeAt(0);
      let alphaDigit = alphaCharCode - 55;
      switch (alphaCharCode) {
        case 73: {
          alphaDigit = 25;
          break;
        }
        case 79: {
          alphaDigit = 35;
          break;
        }
        case 87: {
          alphaDigit = 32;
          break;
        }
        case 90: {
          alphaDigit = 33;
          break;
        }
        default: {
          if (alphaCharCode >= 65 && alphaCharCode <= 72) {
            alphaDigit = alphaCharCode - 55;
          }
          else if (alphaCharCode >= 74 && alphaCharCode <= 78) {
            alphaDigit = alphaCharCode - 56;
          }
          else if (alphaCharCode >= 80 && alphaCharCode <= 86) {
            alphaDigit = alphaCharCode - 57;
          }
          else if (alphaCharCode >= 88 && alphaCharCode <= 90) {
            alphaDigit = alphaCharCode - 58;
          } 
        }
      }
      if (alphaDigit < 10 || alphaDigit > 35) {
        return t('validation.needBePersonId');
      }
      const alphaDigitList = alphaDigit.toString(10).split('');
      const checkDigitList = [
        alphaDigitList[1],
        ...(value.slice(1, 9).split('')),
      ];
      const checkDigitSum = checkDigitList.reduce((digitSum, digit, index) => {
        return digitSum + (parseInt(digit, 10) * (9 - index));
      }, 0) + parseInt(alphaDigitList[0], 10);
      if ((checkDigitSum % 10) === (10 - parseInt(value.slice(-1), 10))) {
        return true;
      }
    }
  }

  return t('validation.needBePersonId');
};

const companyIdBaseRegExp = /^[0-9]{8}$/;
/** 驗證是否符合公司統一編號規則 */
export const needBeCompanyId: Rule = function(value, t) {
  if (typeof value === 'string') {
    if (companyIdBaseRegExp.test(value)) {
      const digitList = value.split('');
      const checkSum = digitList.reduce((sum, digit, index) => {
        let tenPlace = 0;
        let onePlace = 0;
        switch (index) {
          case 0:
          case 2:
          case 4:
          case 7: {
            tenPlace = 0;
            onePlace = parseInt(digit, 10);
            break;
          }
          case 1:
          case 3:
          case 5: {
            const multipleResult = parseInt(digit, 10) * 2;
            tenPlace = Math.floor(multipleResult / 10);
            onePlace = multipleResult % 10;
            break;
          }
          case 6:
          default: {
            break;
          }
        }

        return sum + tenPlace + onePlace;
      }, 0);
      const checkDigit = parseInt(digitList.at(6), 10);
      if (checkDigit === 7) {
        if (checkSum % 5 === 0 || (checkSum + 1) % 5 === 0) {
          return true;
        }
      }
      else {
        const lastMultipleResult = checkDigit * 4;
        const lastTenPlace = Math.floor(lastMultipleResult / 10);
        const lastOnePlace = lastMultipleResult % 10;

        if ((checkSum + lastTenPlace + lastOnePlace) % 5 === 0) {
          return true;
        }
      }
    }
  }

  return t('validation.needBeCompanyId');
};

/** 驗證是否符合「身份證號或公司統一編號」規則 */
export const needBePersonIdOrCompanyId: Rule = function(value, t) {
  if (needBePersonId(value, t) === true) {
    return true;
  }
  else if (needBeCompanyId(value, t) === true) {
    return true;
  }

  return t('validation.needBePersonIdOrCompanyId');
};

const macRegExp = /^([0-9a-fA-F]{2}[:]){5}([0-9a-fA-F]{2})$/;
/** 驗證是否符合「MAC位址」規則 */
export const needBeMacAddress: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    macRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'MAC',
  });
};
const macOrMacMaskRegExp = /^([0-9a-fA-F]{2}[:]){5}([0-9a-fA-F]{2})(?:\/([0-9a-fA-F]{2}[:]){5}([0-9a-fA-F]{2}))?$/;
/** 驗證是否符合「MAC位址或MAC遮罩」規則 */
export const needBeMacOrMacMask: Rule = function(value, t) {
  if (typeof value === 'string') {
    if (macRegExp.test(value) || macOrMacMaskRegExp.test(value)) {
      return true;
    }
  }

  return t('validation.needBeFormat', {
    format: 'MAC(Mask)',
  });
};

const ipv4RegExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
/** 驗證是否符合「IPv4位址」規則 */
export const needBeIpV4: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    ipv4RegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'IPv4',
  });
};

const ipv6RegExp = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?:\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]|(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))))?$/;
/** 驗證是否符合「IPv6位址」規則 */
export const needBeIpV6: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    ipv6RegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'IPv6',
  });
};

const ipv4CIDRRegExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])?\/([0-9]|[1-2][0-9]|3[0-2])?$/;
export const needBeIpV4CIDR: Rule = function(value, t) {
  if (typeof value === 'string' && ipv4CIDRRegExp.test(value)) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'IPv4(range)',
  });
}

const ipv6CIDRRegExp = /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/
export const needBeIpV6CIDR: Rule = function(value, t) {
  if (typeof value === 'string' && ipv6CIDRRegExp.test(value)) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'IPv6(range)',
  });
}

/** 驗證是否符合「IPv4 CIDR或以-分隔的IPv4區段」規則 */
export const needBeIpV4Range: Rule = function(value, t) {
  if (typeof value === 'string') {
    if (ipv4RegExp.test(value) || ipv4CIDRRegExp.test(value)) {
      return true;
    }
    const ipList = value.split('-');
    
    if (ipList.length === 2 && ipv4RegExp.test(ipList[0]) && ipv4RegExp.test(ipList[1])) {
      return true;
    }
  }

  return t('validation.needBeFormat', {
    format: 'IPv4(range)',
  });
};

const domainRegExp = /^(?:(([a-z]{1})|([a-z]{1}[a-z]{1})|([a-z]{1}[0-9]{1})|([0-9]{1}[a-z]{1})|([a-z0-9][a-z0-9\-_]{1,61}[a-z0-9]))\.)+([a-z]{2,6}|[a-z0-9-]{2,30}\.[a-z]{2,3})$/;
/** 驗證是否符合「Domain」規則 */
export const needBeDomain: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    domainRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'Domain',
  });
};

/** 驗證是否符合「Domain或IPv4或IPv6」規則 */
export const needBeHost: Rule = function(value, t) {
  if (
    typeof value === 'string' && (
      ipv4RegExp.test(value) ||
      ipv6RegExp.test(value) ||
      domainRegExp.test(value)
    )
  ) {
    return true;
  }

  return t('validation.needBeHost');
};

const accountRegExp = /^[0-9a-zA-Z._]{1,50}$/;
/** 驗證是否符合帳號規則 */
export const needBeAccount: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    accountRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeAccount');
};

const complexGroupList = [
  [48, 57],
  [65, 90],
  [97, 122],
];
/** 驗證是否符合「有複雜度的密碼」規則 */
export const needBePassword: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    value.length >= 12 &&
    value.length <= 32) {
    const containCharacterGroup = new Set();
    for (let i = 0; i < value.length; i += 1) {
      const code = value.charCodeAt(i);
      if (code >= complexGroupList[0][0] && code <= complexGroupList[0][1]) {
        containCharacterGroup.add(0);
      }
      else if (code >= complexGroupList[1][0] && code <= complexGroupList[1][1]) {
        containCharacterGroup.add(1);
      }
      else if (code >= complexGroupList[2][0] && code <= complexGroupList[2][1]) {
        containCharacterGroup.add(2);
      }
      else {
        containCharacterGroup.add(3);
      }
    }
    if (containCharacterGroup.size === 4) {
      return true;
    }
  }

  return t('validation.needBePassword');
};

const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/** 驗證是否符合「Email地址」規則 */
export const needBeEmail: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    emailRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeFormat', {
    format: 'Email',
  });
};

const phoneRegExp = /^[\d]+$/;
/** 驗證是否符合「電話」規則 */
export const needBePhone: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    phoneRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBePhone');
};

const serialRegExp = /^[a-zA-Z0-9\-_]+$/;
/** 驗證是否符合「只允許大小寫英文、數字、-或_」規則 */
export const needBeSerialString: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    serialRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeSerialString');
};

/** 驗證是否符合「整數」規則 */
export const needBeInteger: Rule = function(value, t) {
  if (
    (
      typeof value === 'string' &&
      String(parseInt(value)) === value
    ) ||
    (
      typeof value === 'number' &&
      Math.floor(value) === value
    )
  ) {
    return true;
  }

  return t('validation.needBeInteger');
};

/** 驗證是否符合「空字串或整數」規則 */
export const needBeEmptyOrInteger: Rule = function(value, t) {
  if (
    (
      typeof value === 'string' &&
      (
        value === '' ||
        String(parseInt(value)) === value
      )
    ) ||
    (
      typeof value === 'number' &&
      Math.floor(value) === value
    )
  ) {
    return true;
  }

  return t('validation.needBeIntegerOrEmpty');
};

/** 驗證是否符合「介於{min}~{max}之間的數字」規則 */
const numberRegExp = /^[0-9\.]+$/;
export const needBeNumberInRange: (max: number, min?: number) => Rule = function(max, min = 1) {
  return (value, t) => {
    const num = parseFloat(value as string);
    if (
      numberRegExp.test(String(value)) &&
      num >= min &&
      num <= max
    ) {
      return true;
    }

    return t('validation.needBeNumberInRange', {
      min,
      max,
    });
  };
};

/** 驗證是否符合「大於N的數字」規則 */
export const needBeNumberGreaterThan: (min: number) => Rule = function(min) {
  return (value, t) => {
    const num = parseFloat(value as string);
    if (
      String(num) === String(value) &&
      num > min
    ) {
      return true;
    }

    return t('validation.needBeNumberGreaterThan', {
      min,
    });
  };
};

/** 若為數字，則小數點後最多只能輸入max位數 */
export const hasMaxDecimal: (max: number) => Rule = function(max = 2) {
  return (value, t) => {
    const strValue = String(value);
    const usdDecimal = strValue.split('.')[1];
    if (usdDecimal && usdDecimal.length > max) {
      return t('validation.hasMaxDecimal', {
        max,
      });
    }

    return true;
  };
};

const alphaNumericRegExp = /^[0-9A-Za-z]+$/;
/** 驗證是否符合「英數字」規則 */
export const needBeAlphaNumeric: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    alphaNumericRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeAlphaNumeric');
};

const chineseAlphaNumericRegExp = /^[a-zA-Z0-9\u4E00-\u9FA5]*$/;
/** 驗證是否符合「中英數字」規則 */
export const needBeChinsesAlphaNumeric: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    chineseAlphaNumericRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeChineseAlphaNumeric');
};

const hexRegExp = /^[0-9A-Fa-f]+$/;
/** 驗證是否符合「十六進位字串」規則 */
export const needBeHexNumber: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    hexRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeHexNumber');
};

const hexStringRegExp = /^0x[0-9A-Fa-f]+$/;
/** 驗證是否符合「嚴謹十六進位字串（必須以0x開頭）」規則 */
export const needBeHexNumberStringInRange: (max: string, min?: string) => Rule = function(max, min = '0x0001') {
  return (value, t) => {
    const strValue = String(value);
    if (hexStringRegExp.test(strValue)) {
      const num = parseInt(strValue, 16);
      if (num >= parseInt(min, 16) && num <= parseInt(max, 16)) {
        return true;
      }
    }

    return t('validation.needBeHexNumberStringInRange', {
      min,
      max,
    });
  };
};

/** 驗證是否符合「長度在{min}與{max}之間的字串」規則 */
export const needBeStringInRange: (max: number, min?: number) => Rule = function(max, min = 0) {
  return (value, t) => {
    const checkValue = String(value);
    if (
      checkValue === value &&
      checkValue.length >= min &&
      checkValue.length <= max
    ) {
      return true;
    }

    return t('validation.needBeStringInRange', {
      min,
      max,
    });
  };
};

/** 驗證是否符合「排除指定特定字元的字串」規則 */
export const needBeStringExclude: (character: string) => Rule = function(character: string) {
  return (value, t) => {
    const checkValue = String(value);
    if (
      checkValue === value &&
      checkValue.indexOf(character) === -1
    ) {
      return true;
    }

    return t('validation.needBeStringExclude', {
      character,
    });
  };
};

/** 驗證是否符合「以指定符號為分隔的字串」規則，預設以換行或逗號為分隔 */
export const needBeStringList: (validation: Rule, stringFormat: string, splitter?: RegExp) => Rule = function(validation, stringFormat, splitter = /,|\n/) {
  return (value, t) => {
    const checkValue = String(value);
    const checkValueList = checkValue.split(splitter);
    const listResult = checkValueList.every((value) => {
      return validation(value, t) === true;
    });
    if (listResult === true) {
      return true;
    }

    return t('validation.needBeStringListSplitByCommaOrNewLine', {
      stringFormat,
    });
  };
};

/** 驗證是否符合「以指定符號為分隔的整數或整數區間」規則，預設以換行或逗號為分隔，以-作為整數區間表示 */
export const needBeIntegerOrIntegerRangeStringListSplitByComma: (max: number, min?: number, splitter?: RegExp) => Rule = function(max, min = 1, splitter = /,/) {
  return (value, t) => {
    const checkValue = String(value);
    const checkValueList = checkValue.split(splitter);
    const listResult = checkValueList.every((valueString) => {
      const numList = valueString.split('-');
      if (numList.length === 1) {
        const numString = numList[0];
        const num = parseInt(numString);
        if (String(num) !== numString) {
          return false;
        }
        if (num < min || num > max) {
          return false;
        }
  
        return true;
      }
      else if (numList.length === 2) {
        const [ numString1, numString2 ] = numList;
        const [ num1, num2 ] = numList.map((numString) => parseInt(numString));
        if (String(num1) !== numString1 || String(num2) !== numString2) {
          return false;
        }
        if (num1 >= num2) {
          return false;
        }
        if (num1 < min || num1 > max) {
          return false;
        }
        if (num2 < min || num2 > max) {
          return false;
        }
  
        return true;
      }
      else {
        return false;
      }
    });
    if (listResult === true) {
      return true;
    }

    return t('validation.needBeIntegerOrIntegerRangeStringListSplitByComma', {
      min,
      max,
    });
  };
};

const s3BucketRegExp = /^[0-9a-z-]{3,63}$/;
/** 驗證是否符合「S3儲存盒名稱」規則 */
export const needBeS3Bucket: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    s3BucketRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeS3Bucket');
};

const s3FolderRegExp = /^[0-9a-zA-Z]+$/;
/** 驗證是否符合「S3儲存盒名稱」規則 */
export const needBeS3Folder: Rule = function(value, t) {
  if (
    typeof value === 'string' &&
    s3FolderRegExp.test(value)
  ) {
    return true;
  }

  return t('validation.needBeS3Folder');
};

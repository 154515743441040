// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RHaB6OeaSeCkswN7gTxx{position:fixed;width:100vw;left:0;top:0;z-index:3001}.RHaB6OeaSeCkswN7gTxx .MuiAlert-root{min-width:20rem;margin:1rem auto}", "",{"version":3,"sources":["webpack://./components/notifyList/notifyList.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,MAAA,CACA,KAAA,CACA,YAAA,CAEA,qCACE,eAAA,CACA,gBAAA","sourcesContent":[":local(.component) {\n  position: fixed;\n  width: 100vw;\n  left: 0;\n  top: 0;\n  z-index: 3001;\n\n  .MuiAlert-root {\n    min-width: 20rem;\n    margin: 1rem auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "RHaB6OeaSeCkswN7gTxx"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p210wOYDoh0L5Z4gba7S{position:fixed;left:0;top:0;z-index:1600;background:rgba(0,0,0,.5);text-align:center}.p210wOYDoh0L5Z4gba7S .MuiCircularProgress-root{color:#fff}", "",{"version":3,"sources":["webpack://./components/loadingScreen/loadingScreen.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,MAAA,CACA,KAAA,CACA,YAAA,CACA,yBAAA,CACA,iBAAA,CAEA,gDACE,UAAA","sourcesContent":[":local(.component) {\n  position: fixed;\n  left: 0;\n  top: 0;\n  z-index: 1600;\n  background: rgba(0, 0, 0, 0.5);\n  text-align: center;\n\n  .MuiCircularProgress-root {\n    color: #fff;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "p210wOYDoh0L5Z4gba7S"
};
export default ___CSS_LOADER_EXPORT___;

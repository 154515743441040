import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getRouteHref, useIsMatchRoute } from 'src/hooks/useNavigate';
import { useLoadAuthority } from 'src/data/user/authority';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface NavGroup {
  id: string,
  text: string,
  children: NavItem[],
}
interface NavLink {
  text: string,
  useRoute: string,
}
type NavItem = NavGroup | NavLink;
function isNavGroup(navItem: NavItem): navItem is NavGroup {
  return Boolean((navItem as NavGroup).children);
}

export function Sidebar() {
  const { t } = useTranslation();
  const isMatchRoute = useIsMatchRoute();
  const { ready, authority } = useLoadAuthority();

  const navItemList = React.useMemo(() => {
    if (!ready) {
      return [];
    }
    const responseList = authority.menu.slice();
    // 先排序
    responseList.sort((a, b) => a.sort - b.sort);
    // 將rawResponse轉為NavItem/NavGroup，並暫存於hash中
    const navItemHash: Record<number, NavItem> = {};
    responseList.forEach((rawMenu) => {
      if (rawMenu.link === '') {
        navItemHash[rawMenu.id] = {
          id: String(rawMenu.id),
          text: rawMenu.lang_key,
          children: [],
        };
      }
      else {
        navItemHash[rawMenu.id] = {
          text: rawMenu.lang_key,
          useRoute: rawMenu.link,
        };
      }
    });
    const resultList: NavItem[] = [];
    // 依排序後的reportList計算出menu
    responseList.forEach((rawMenu) => {
      // 若parentId為0，直接依序放進resultList中
      if (rawMenu.parentId === 0) {
        resultList.push(navItemHash[rawMenu.id]);
      }
      // 否則將其置入其應存在parent group裡。
      else {
        const parsedNavItem = navItemHash[rawMenu.id];
        const parentGroup = navItemHash[rawMenu.parentId];
        if (parsedNavItem && parentGroup && isNavGroup(parentGroup)) {
          parentGroup.children.push(parsedNavItem);
        }
      }
    });

    return resultList;

  }, [ ready, authority.menu ]);
  const currentActivedNavigateGroupIdList = React.useMemo(() => {
    let idList: string[] = [];
    const findIdList = (navItems: NavItem[], parentIdList: string[] = []) => {
      navItems.forEach((navItem) => {
        if (isNavGroup(navItem)) {
          findIdList(navItem.children, [ ...parentIdList, navItem.id ]);
        } else if (isMatchRoute(navItem.useRoute)) {
          idList = [ ...idList, ...parentIdList ];
        }
      });
    };
    findIdList(navItemList);
    return Array.from(new Set(idList));
  }, [ isMatchRoute, navItemList ]);
  const [ openedIdList, setOpenedIdList ] = React.useState<string[]>(currentActivedNavigateGroupIdList);

  const getLinkNodeList = React.useCallback((navItems: NavItem[], level = 0) => navItems.map((navItem, navIndex) => {
    const icons = Array(level).fill(null).map((_, i) => (
      <ArrowForwardIosIcon key={ i } />
    ));
    if (isNavGroup(navItem)) {
      const handleClickNavGroup = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const index = openedIdList.indexOf(navItem.id);
        if (index !== -1) {
          openedIdList.splice(index, 1);
          setOpenedIdList([ ...openedIdList ]);
        }
        else {
          const newOpenedIndexList = [ ...openedIdList, navItem.id ];
          setOpenedIdList(newOpenedIndexList);
        }
      };
      const isOpen = openedIdList.includes(navItem.id);
      const classNames = [ 'group', `level-${level}` ];
      if (isOpen) classNames.push('active');
      const childrenNavList = getLinkNodeList(navItem.children, level + 1);

      return (
        <div key={ navIndex }>
          <a
            href="#"
            className={ classNames.join(' ') }
            onClick={ handleClickNavGroup }>
            { icons }
            { t(`console.sidebar.${navItem.text}`) }
          </a>
          { isOpen ? childrenNavList : null }
        </div>
      );
    }
    else {
      const href = getRouteHref(navItem.useRoute);
      const classNames = [ `level-${level}` ];
      if (isMatchRoute(navItem.useRoute)) { 
        classNames.push('active');
      }

      return (
        <a
          key={ navIndex }
          href={ href }
          className={ classNames.join(' ') }>
          { icons }
          { t(`console.sidebar.${navItem.text}`) }
        </a>
      );
    }
  }), [ isMatchRoute, openedIdList, t ]);

  return (
    <nav
      className="sidebar">
      { getLinkNodeList(navItemList) }
    </nav>
  );
}
export default Sidebar;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
const languageTextHash = {
  'zh-TW': '中文(繁體)',
  // 'zh-CN': '中文(简体)',
  'en': 'English',
};

export function LanguageButton() {
  const { i18n } = useTranslation();
  const buttonRef = React.useRef(null);
  const [ isShowMenu, setIsShowMenu ] = React.useState(false);
  const changeLanguage = React.useCallback((language: string) => {
    localStorage.setItem('useLanguage', language);
    i18n.changeLanguage(language);
    setIsShowMenu(false);
  }, [ i18n, setIsShowMenu ]);

  const languageList = Object.keys(languageTextHash).map((key: 'zh-TW' | 'en') => {
    return (
      <MenuItem
        key={ key }
        sx={{
          background() {
            return i18n.language === key ? '#ddd' : '#fff';
          },
          '&:hover': {
            background: '#aaa',
          },
        }}
        onClick={ () => { changeLanguage(key) } }
        divider>
        { languageTextHash[key] }
      </MenuItem>
    );
  });

  return (
    <>
      <Button
        ref={ buttonRef }
        variant="text"
        size="large"
        endIcon={ <ArrowDropDownIcon /> }
        onClick={ () => { setIsShowMenu(true); } }>
        { languageTextHash[i18n.language] }
      </Button>
      <Menu
        anchorEl={ buttonRef.current }
        open={ isShowMenu }
        onClose={ () => { setIsShowMenu(false); } }
        PaperProps= {{
          elevation: 0,
          sx: {
            py: 0,
            borderRadius: 0,
          },
        }}>
        { languageList }
      </Menu>
    </>
  );
}
export default LanguageButton;

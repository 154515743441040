import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Unstable_Grid2 as Grid,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useCheckTokenThrottleOnMount } from 'src/data/user/info';
import { useLogoutUser } from 'src/hooks/useLoginUser';
import { useRequest } from 'src/utils/RESTful';
import { useMemoRouteHref, getRouteHref, useCurrentRoute, useNavigate } from 'src/hooks/useNavigate';
import { useLoadAllThrottleOnMount } from 'src/data/portal/products';
import { LinkMenu } from './LinkMenu';

export function NavLarge() {
  const { t } = useTranslation();
  const { ready, isLogin } = useCheckTokenThrottleOnMount();
  const request = useRequest();
  const logoutUser = useLogoutUser();
  const onClickLogout = React.useCallback(async () => {
    await request({
      url: '/users/logout',
      method: 'post',
      headers: {
        'X-LOG-CODE': '10008',
      },
    });
    logoutUser();
  }, [ request, logoutUser ]);
  const loginHref = useMemoRouteHref('Login');
  const loginoutButton = React.useMemo(() => {
    if (ready && isLogin) {
      return (
        <Button onClick={ onClickLogout }>
          { t('portal.nav.logout') }
        </Button>    
      );
    }
    else {
      return (
        <Button href={ loginHref }>
          { t('portal.nav.login') }
        </Button>
      );
    }
  }, [ t, ready, isLogin, onClickLogout, loginHref ]);
  const registerQueryHref = useMemoRouteHref('RegisterQuery');
  const registerHref = useMemoRouteHref('Register');
  const lastestNewsHref = useMemoRouteHref('LastestNews');

  return (
    <Grid
      className="largeNav"
      component="nav"
      container
      spacing={ 1 }>
      <Grid
        className="up"
        xs={ 12 }>
        <Button
          href={ t('portal.nav.contactUsUrl') }
          target="_blank">
          { t('portal.nav.contactUs') }
        </Button>
        <LanguageButton />
        { loginoutButton }
        <Button href={ registerQueryHref }>
          { t('portal.nav.registerQuery') }
        </Button>
        <Button
          className="register"
          href={ registerHref }>
          { t('portal.nav.createAccount') }
        </Button>
      </Grid>
      <Grid
        className="down"
        xs={12}>
        <LinkMenu
          text={ t('portal.nav.lastestNews') }
          href={ lastestNewsHref } />
        <ProductButton />
        <Button
          href={ API_URL_ROOT + 'home/solution' }
          target="_blank">
          { t('portal.nav.solution') }
        </Button>
        <Button
          href={ API_URL_ROOT + 'home/pricing' }
          target="_blank">
          { t('portal.nav.pricing') }
        </Button>
        <Button
          href={ API_URL_ROOT + 'home/download' }
          target="_blank">
          { t('portal.nav.documentDownload') }
        </Button>
      </Grid>
    </Grid>
  );
}

const languageTextHash = {
  'zh-TW': '中文(繁體)',
  'en': 'English',
};

function LanguageButton() {
  const { i18n } = useTranslation();
  const buttonRef = React.useRef(null);
  const [ isShowMenu, setIsShowMenu ] = React.useState(false);
  const showMenu = () => {
    setIsShowMenu(true);
  };
  const hideMenu = () => {
    setIsShowMenu(false);
  };

  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const changeLanguage = (language: keyof typeof languageTextHash) => {
    localStorage.setItem('useLanguage', language);
    i18n.changeLanguage(language);
    setIsShowMenu(false);
    if (currentRoute.id === 'FraudPrevent' && language === 'en') {
      navigate('Home');
    }
  };

  return (
    <>
      <Button
        ref={ buttonRef }
        sx={{ color: 'black' }}
        endIcon={ <ArrowDropDownIcon /> }
        onClick={showMenu}>
        { languageTextHash[i18n.language] }
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={isShowMenu}
        onClose={hideMenu}
        PaperProps= {{
          square: true,
          elevation: 0,
          sx: {
            py: 0,
            borderRadius: 0,
          },
        }}>
        <MenuItem
          sx={{
            background() {
              return i18n.language === 'zh-TW' ? '#fb0' : '#fff';
            },
            '&:hover': {
              background() {
                return i18n.language === 'zh-TW' ? '#fb0' : '#fd0';
              },
            },
          }}
          onClick={ () => changeLanguage('zh-TW') }
          divider>
          { languageTextHash['zh-TW'] }
        </MenuItem>
        <MenuItem
          sx={{
            background() {
              return i18n.language === 'en' ? '#fb0' : '#fff';
            },
            '&:hover': {
              background() {
                return i18n.language === 'en' ? '#fb0' : '#fd0';
              },
            },
          }}
          onClick={ () => changeLanguage('en') }>
          { languageTextHash['en'] }
        </MenuItem>
      </Menu>
    </>
  );
}

function ProductButton() {
  const { t, i18n } = useTranslation();
  const productList = useLoadAllThrottleOnMount();
  const children = React.useMemo(() => {
    const resultList = productList.map((productData) => {
      return {
        text: i18n.language === 'en' ? productData.productNameE : productData.productNameC,
        href: getRouteHref('Product', {
          id: String(productData.id),
          view: 'overview',
        }),
        target: '_top',
      };
    });
    /** https://redmine.yesee.com.tw/issues/5319#note-11 */
    resultList.push({
      text: t('portal.nav.productExtra1'),
      href: 'https://chiefbmc.chiefappc.com/',
      target: '_blank',
    }, {
      text: t('portal.nav.productExtra2'),
      href: 'https://ccis2.chiefappc.com/',
      target: '_blank',
    });

    return resultList;
  }, [ productList, i18n.language, t ]);

  return (
    <LinkMenu text={ t('portal.nav.products') }>
      { children }
    </LinkMenu>
  );
}

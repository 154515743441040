import * as React from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import styles from './confirmDialog.scss';

interface ConfirmDialogState {
  isOpen: boolean,
  title: string,
  hideConfirm: boolean,
  hideCancel: boolean,
}
type Action = () => void;

const confirmDialogGlobalState = hookstate<ConfirmDialogState>({
  isOpen: false,
  title: '',
  hideConfirm: false,
  hideCancel: false,
});

let confirmDialogBody: React.ReactNode = '';
let confirmDialogConfirmAction: Action | false = false;
let confirmDialogCancelAction: Action | false = false;
export function ConfirmDialog() {
  const { t } = useTranslation();
  const state = useHookstate(confirmDialogGlobalState);
  const currentState = state.get();
  const onCloseDialog = React.useCallback(() => {
    state.set({
      title: '',
      isOpen: false,
      hideConfirm: false,
      hideCancel: false,
    });
  }, [ state ]);
  const onConfirm = React.useCallback(() => {
    if (confirmDialogConfirmAction) {
      confirmDialogConfirmAction();
    }
    onCloseDialog();
  }, [ onCloseDialog ]);
  const confirmNode = React.useMemo(() => {
    if (currentState.hideConfirm) {
      return null;
    }

    return (
      <Button
        className="confirm"
        onClick={ onConfirm }>
        { t('confirmDialog.confirm') }
      </Button>
    );
  }, [ currentState.hideConfirm, onConfirm, t ]);
  const onCancel = React.useCallback(() => {
    if (confirmDialogCancelAction) {
      confirmDialogCancelAction();
    }
    onCloseDialog();
  }, [ onCloseDialog ]);
  const cancelNode = React.useMemo(() => {
    if (currentState.hideCancel) {
      return null;
    }

    return (
      <Button
        className="cancel"
        onClick={ onCancel }>
        { t('confirmDialog.cancel') }
      </Button>
    );
  }, [ currentState.hideCancel, onCancel, t ]);

  return (
    <Dialog
      className={ styles.component }
      onClose={ onCloseDialog }
      open={ currentState.isOpen }>
      <DialogTitle>
        { currentState.title }
      </DialogTitle>
      <DialogContent dividers>
        { confirmDialogBody }
      </DialogContent>
      <DialogActions>
        { confirmNode }
        { cancelNode }
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmDialog;

interface ShowConfirmDialogArgs {
  title: ConfirmDialogState['title'],
  body: React.ReactNode,
  onConfirm?: Action,
  hideConfirm?: boolean,
  onCancel?: Action,
  hideCancel?: boolean,
}
export function useShowConfirmDialog() {
  const state = useHookstate(confirmDialogGlobalState);

  return React.useCallback((args: ShowConfirmDialogArgs) => {
    confirmDialogBody = args.body;
    confirmDialogConfirmAction = args.onConfirm || false;
    confirmDialogCancelAction = args.onCancel || false;
    state.set({
      ...args,
      isOpen: true,
      hideConfirm: args.hideConfirm || false,
      hideCancel: args.hideCancel || false,
    });
  }, [ state ]);
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import zhTW from './zh-TW';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      'zh-TW': zhTW,
    },
    lng: localStorage.getItem('useLanguage') || 'zh-TW',
    fallbackLng: 'zh-TW',
    ns: ['default'],
    defaultNS: 'default',
    fallbackNS: ['default'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ignoreJSONStructure: false,
  });

export default i18n;

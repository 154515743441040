import * as React from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { Stack, Alert, Fade } from '@mui/material';
import styles from './notifyList.scss';

interface Notify {
  id: number,
  type: 'error' | 'warning' | 'info' | 'success',
  message: string,
  timeout: number,
}
const NotifyListGlobalState = hookstate<Notify[]>([]);

export function NotifyList() {
  const state = useHookstate(NotifyListGlobalState);
  const notifyList = state.get().map((notify) => {
    return Object.assign({}, notify);
  });

  const clearExpireNotify = React.useCallback(() => {
    const now = Date.now();
    const newNotifyList = [];
    let isSomethingExpire = false;
    notifyList.forEach((notify) => {
      if (now > notify.timeout) {
        isSomethingExpire = true;
      }
      else {
        newNotifyList.push(notify);
      }
    });
    if (isSomethingExpire) {
      state.set(newNotifyList);
    }
  }, [ notifyList, state ]);

  React.useEffect(() => {
    const intervalId = setInterval(clearExpireNotify, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [ clearExpireNotify ]);

  const removeNotify = React.useCallback((id: number) => {
    state.set((notifyList) => {
      return notifyList.filter((notify) => {
        return notify.id !== id;
      });
    });
  }, [ state ]);

  const alertList = React.useMemo(() => {
    return notifyList.map((notify) => {
      return (
        <Fade
          key={ notify.id }
          in={ true }
          timeout={ 500 }>
          <Alert
            severity={ notify.type }
            onClose={() => { removeNotify(notify.id); }}>
            { notify.message }
          </Alert>
        </Fade>
      );
    });
  }, [ notifyList, removeNotify ]);

  return (
    <Stack
      className={ styles.component }
      spacing={ 2 }>
      { alertList }
    </Stack>
  );
}
export default NotifyList;

let notifyIdCount = 0;
export function useNotify(type: Notify['type']) {
  const state = useHookstate(NotifyListGlobalState);

  return React.useCallback((message: string) => {
    notifyIdCount += 1;
    state.set((prevState) => {
      const newState = prevState.slice();
      newState.push({
        id: notifyIdCount,
        type,
        message,
        timeout: Date.now() + 5000,
      });

      return newState;
    });
  }, [ state, type ]);
}

import * as React from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { Box, CircularProgress } from '@mui/material';
import styles from './loadingScreen.scss';

const LoadingTaskGlobalState = hookstate(0);

export function LoadingScreen() {
  const [ screenWidth, setScreenWidth ] = React.useState(window.innerWidth);
  const [ screenHeight, setScreenHeight ] = React.useState(window.innerHeight);
  React.useEffect(() => {
    const onWindowResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);
  const state = useHookstate(LoadingTaskGlobalState);

  if (state.get() > 0) {
    return (
      <Box
        className={ styles.component }
        style={{
          width: `${screenWidth}px`,
          height: `${screenHeight}px`,
          paddingTop: `${(screenHeight - 50) / 2}px`,
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return null;
}
export default LoadingScreen;

export function useLoadingAction() {
  const state = useHookstate(LoadingTaskGlobalState);
  const addTask = React.useCallback(() => {
    state.set((task) => task + 1);
  }, [ state ]);
  const resolveTask = React.useCallback(() => {
    state.set((task) => task - 1);
  }, [ state ]);

  return React.useMemo(() => {
    return { addTask, resolveTask };
  }, [ addTask, resolveTask ]);
}

import * as React from 'react';

export function useWatchWindowSize() {
  const [ width, setWidth ] = React.useState(window.innerWidth);
  const [ height, setHeight ] = React.useState(window.innerHeight);
  const handleResize = React.useCallback(() => {
    if (window.innerWidth !== width) {
      setWidth(window.innerWidth);
    }
    if (window.innerHeight !== height) {
      setHeight(window.innerHeight);
    }
  }, [ width, height]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ handleResize ]);

  return {
    width,
    height,
  };
}
export default useWatchWindowSize;
import * as React from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { AuthorityTree, AuthorityValue, useLoadAuthority } from 'src/data/user/authority';

export interface LoginUser {
  token: string,
  identityNumber: string,
  companyName: string,
  clientNumber: string,
  email: string,
  twoFa: 'Y' | 'N',
  isRoot: boolean,
  contactName: string,
  contactTel: string,
  contactMobile: string,
}
export function defaultUserData() {
  return {
    token: '',
    identityNumber: '',
    companyName: '',
    clientNumber: '',
    email: '',
    twoFa: 'N',
    isRoot: false,
    contactName: '',
    contactTel: '',
    contactMobile: '',
  } as LoginUser;
}

const LoginUserGlobalState = hookstate<LoginUser>({
  token: localStorage.getItem('authorization'),
  identityNumber: '',
  companyName: '',
  clientNumber: '',
  email: '',
  twoFa: 'Y',
  isRoot: false,
  contactName: '',
  contactTel: '',
  contactMobile: '',
});

export function useLoginUser() {
  const state = useHookstate(LoginUserGlobalState);

  return React.useMemo(() => {
    return state.get();
  }, [ state ]);
}
export default useLoginUser;

export function useSetLoginUser() {
  const state = useHookstate(LoginUserGlobalState);

  return React.useCallback((user: Partial<LoginUser>) => {
    checkAuthorityResultMap.clear();
    if (user.token !== undefined) {
      localStorage.setItem('authorization', user.token);
    }
    state.set((state) => {
      return Object.assign(state, user);
    });
  }, [ state ]);
}

export function useLogoutUser() {
  const state = useHookstate(LoginUserGlobalState);

  return React.useCallback(() => {
    state.set(defaultUserData());
  }, [ state ]);
}

const checkAuthorityResultMap = new Map<string, boolean>();
export function useCheckAuthorityResult(checkString = '') {
  const { ready, authority } = useLoadAuthority();

  return React.useMemo(() => {
    if (!ready) {
      return false;
    }
    if (checkAuthorityResultMap.has(checkString)) {
      return checkAuthorityResultMap.get(checkString);
    }
    const result = checkAuthority(checkString, authority.authority);
    checkAuthorityResultMap.set(checkString, result);

    return result;
  }, [ ready, checkString, authority ]);
}

export function checkAuthority(checkString = '', authority: AuthorityValue) {
  const trackingSequence = checkString.split('/');
  if (typeof authority !== 'object') {
    return Boolean(authority);
  }

  let trackingAuthority: AuthorityTree = authority;
  let trackingResult: AuthorityValue = false;
  for (const trackingKey of trackingSequence) {
    const tempResult = trackingAuthority[trackingKey];
    if (typeof tempResult !== 'object') {
      return Boolean(tempResult);
    }
    trackingResult = true;
    trackingAuthority = tempResult;
  }

  return trackingResult;
}

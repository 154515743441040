import * as React from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Link,
  SxProps,
  PaperProps,
} from '@mui/material';
import { useResizeObserver } from 'src/hooks/useResizeObserver';

const menuPaperProps: PaperProps = {
  square: true,
  elevation: 0,
  sx: {
    borderTop: 'none',
    borderRadius: 0,
  },
};
const menuItemSx: SxProps = {
  color: '#000',
  background: '#fff',
  '&:hover': {
    color: '#ff4c00',
    background: '#fff',
  },
};

interface LinkMenuProps {
  text: string,
  href?: string,
  children?: {
    text: string,
    href: string,
    target?: string,
  }[],
}
export function LinkMenu(props: LinkMenuProps) {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const [ buttonWidth, seButtonWidth ] = React.useState(0);
  useResizeObserver(buttonRef, (rect) => {
    seButtonWidth(rect.width + 15);
  });

  const [ menuWidth, setMenuWidth ] = React.useState(0);
  const [ isShowMenu, setIsShowMenu ] = React.useState(false);
  const showMenu = () => {
    if (props.children && props.children.length) {
      setIsShowMenu(true);
    }
  };
  const hideMenu = () => {
    setIsShowMenu(false);
  };
  const menuItemList = (props.children || []).map((child, index, children) => {
    return (
      <MenuItem
        key={index}
        sx={menuItemSx}
        divider={ index !== (children.length - 1) }>
        <Link
          href={ child.href }
          target={ child.target || '_top' }
          underline="none"
          color="#000">
          { child.text }
        </Link>
      </MenuItem>
    );
  });

  return (
    <>
      <Button
        ref={buttonRef}
        href={props.href}
        onClick={showMenu}>
        { props.text }
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: (buttonWidth - menuWidth) / 2,
        }}
        open={isShowMenu}
        onClose={hideMenu}
        PaperProps={ menuPaperProps }>
        <ArrowUp widthCallback={setMenuWidth} />
        { menuItemList }
      </Menu>
    </>
  );
}
export default LinkMenu;

const arrowWrapperStyle: React.CSSProperties = {
  position: 'relative',
  borderTop: '2px solid #ff4c00',
};

interface ArrowUpProps {
  widthCallback: React.Dispatch<React.SetStateAction<number>>,
}
function ArrowUp(props: ArrowUpProps) {
  const { widthCallback } = props;
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [ wrapperWidth, seWrapperWidth ] = React.useState(0);
  useResizeObserver(wrapperRef, (rect) => {
    widthCallback(rect.width);
    seWrapperWidth(rect.width);
  });

  return (
    <div
      ref={wrapperRef}
      style={arrowWrapperStyle}>
      <div style={{
        position: 'absolute',
        left: `${(wrapperWidth - 20) / 2}px`,
        top: '-10px',
        width: '0px',
        height: '0px',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #ff4c00',
      }} />
    </div>
  );
}
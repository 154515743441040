import * as React from 'react';
import {
  AppBar,
  Unstable_Grid2 as Grid,
  Link,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavLarge } from './NavLarge';
import { NavSmall } from './NavSmall';
import { useMemoRouteHref } from 'src/hooks/useNavigate';
const logo = require('src/assets/portal/chief-logo.png').default;

export function Header() {
  const theme = useTheme();
  const useLargeNav = useMediaQuery(theme.breakpoints.up('md'));
  const useNav = React.useMemo(() => {
    return useLargeNav ? <NavLarge /> : <NavSmall />;
  }, [ useLargeNav ]);

  return (
    <AppBar
      position="relative"
      elevation={ 0 }>
      <Grid
        className="header"
        container
        spacing={ 0 }>
        <Grid
          className="link"
          xs="auto">
          <Link
            underline="none"
            href={ useMemoRouteHref('Home') }>
            <Box sx={{
              height: {
                xs: '2.5rem',
                md: '5rem',
              },
            }}>
              <img
                className="logo"
                src={ logo } />
            </Box>
          </Link>
        </Grid>
        <Grid xs>
          { useNav }
        </Grid>
      </Grid>
    </AppBar>
  );
}
export default Header;

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'src/i18n/index';
import { ConfirmDialog } from 'src/components/confirmDialog/ConfirmDialog';
import { LoadingScreen } from 'src/components/loadingScreen/LoadingScreen';
import { NotifyList } from 'src/components/notifyList/NotifyList';
import { Layout } from 'src/components/layout/Layout';

const theme = createTheme({
  palette: {
    warning: {
      main: '#ffa726',
    },
    error: {
      main: '#ff0000',
    },
    info: {
      main: '#59c1b3',
    },
    success: {
      main: '#00aa00',
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         textTransform: 'initial',
  //       },
  //     },
  //   },
  // },
});

function Index() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={ theme }>
        <CssBaseline enableColorScheme />
        <HashRouter>
          <Layout />
        </HashRouter>
        <ConfirmDialog />
        <LoadingScreen />
        <NotifyList />
      </ThemeProvider>
    </React.StrictMode>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Index />);

import * as React from 'react';
import {
  useNavigate as useOriginNavigate,
  generatePath,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { routes, RouteSetting } from 'src/utils/routes';

function findRoute(id: RouteSetting['id']) {
  return routes.find((routeSetting) => {
    return routeSetting.id === id;
  });
}

export function getRouteHref(id: RouteSetting['id'], params: Record<string, string> = {}) {
  const routeSetting = findRoute(id);
  if (!routeSetting) {
    return '#';
  }
  const usePath = routeSetting.path || routeSetting.id;
  const path = generatePath(usePath, params);

  return `#${path}`;
}

export function useMemoRouteHref(id: RouteSetting['id'], params: Record<string, string> = {}) {
  return React.useMemo(() => {
    return getRouteHref(id, params);
  }, [id, params]);
}

export function useNavigate() {
  const navigate = useOriginNavigate();

  return React.useCallback((id: string, params: Record<string, string> = {}) => {
    const routeSetting = findRoute(id);
    if (!routeSetting) {
      throw Error('Found no match route setting!');
    }
    let path = routeSetting.path;
    Object.keys(params).forEach((key) => {
      const value = params[key];
      path = path.replace(`:${key}`, value);
    });

    navigate(path);
  }, [navigate]);
}

export function useCurrentRoute() {
  const location = useLocation();

  return routes.find((route) => {
    return Boolean(matchPath(route.path, location.pathname));
  });
}

export function useIsMatchRoute() {
  const location = useLocation();

  return function isMatchRoute(id: RouteSetting['id'], params: Record<string, string> = {}) {
    const routeSetting = findRoute(id);
    if (!routeSetting) {
      return false;
    }
    const usePath = routeSetting.path || routeSetting.id;
    const path = generatePath(usePath, params);
    
    return path === location.pathname;
  };
}

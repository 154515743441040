import * as React from 'react';
import {
  Container,
  Box,
} from '@mui/material';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Pages } from './Pages'
import { useWatchWindowSize } from 'src/hooks/useWatchWindowSize';
import styles from './consoleLayout.scss';
import { useLoadInfoThrottleOnMount } from 'src/data/user/info';

export function ConsoleLayout() {
  const { height } = useWatchWindowSize();
  const contentHeight = height - 64;

  const { ready } = useLoadInfoThrottleOnMount();

  return (
    <Container
      className={ styles.layout }
      maxWidth={ false }
      disableGutters>
      <Header />
      <Box className="content">
        {ready &&
          <>
            <Sidebar />
            <Pages height={ contentHeight } />
          </>
        }
      </Box>
    </Container>
  );
}
export default ConsoleLayout;

import * as React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useBasicApiConfig } from 'src/hooks/useBasicApiConfig';
import { ValidateableData, RuleSet, ExtraValidate } from './validations';
import { useLoadingAction } from 'src/components/loadingScreen/LoadingScreen';
import { useNotify } from 'src/components/notifyList/NotifyList';
import { useNavigate } from 'src/hooks/useNavigate';
import { useLogoutUser } from 'src/hooks/useLoginUser';

/** 一般RESTful會得到的response，其中data欄位的格式 */
export interface RESTfulResponse<Data = unknown> {
  code: number,
  msg: string,
  data: Data,
}

/** 「遠端分頁」類型的RESTful API會得到的response，其中data欄位的格式 */
export interface RecordsData<Schema = unknown> {
  records: Schema[],
  page: number,
  pages: number,
  limit: number,
  sort: string[],
  orderby: string[],
  total: number,
}

/** 所有基本RESTful資源一定有string格式的id欄位，且一定是以string為欄位鍵值。 */
export interface BasicSchema extends ValidateableData {
  id: string,
}

interface RESTfulConfigConstructorArg<Schema> {
  /** RESTful資源的基本url前綴 */
  baseUrl: string,
  /** 回傳RESTful資源的預設值，當server取回的資料有缺時，確保資源的schema不被破壞。 */
  default: (partialData?: Partial<Schema>) => Schema,
  /** RESTful資源的轉換方法，對server取回的資料進行轉換以符合本機端需求。預設會將所有number id轉為string格式。 */
  parser?: (response: unknown) => Schema;
}

export class RESTfulConfig<Schema extends BasicSchema> {
  public readonly baseUrl: RESTfulConfigConstructorArg<Schema>['baseUrl'];
  public readonly default: RESTfulConfigConstructorArg<Schema>['default'];
  public readonly parser: RESTfulConfigConstructorArg<Schema>['parser'];

  constructor(options: RESTfulConfigConstructorArg<Schema>) {
    this.baseUrl = options.baseUrl;
    this.default = options.default;
    this.parser = options.parser || function defaultParser(serverRawData: unknown) {
      const data = serverRawData as Schema;

      return Object.assign(options.default(), data, {
        id: typeof data?.id === 'number' ? String(data.id) : data?.id,
      });
    };
  }
}
export default RESTfulConfig;

/** 取得useForm所需的validate前端驗證器。 */
export function useValidation(rules: RuleSet, extraValidation?: ExtraValidate) {
  const { t } = useTranslation();

  return React.useCallback((data: Record<string, unknown>) => {
    const errors = {};
    for (const fieldName in rules) {
      const ruleList = rules[fieldName];
      if (ruleList && ruleList.length) {
        let validateResult: string | true = true;
        ruleList.some((rule) => {
          validateResult = rule(data[fieldName], t);
          if (typeof validateResult === 'string') {
            return true;
          }
        });
        if (typeof validateResult === 'string') {
          errors[fieldName] = validateResult;
        }
      }
    }
    if (extraValidation) {
      return extraValidation(data, errors);
    }
    else {
      return errors;
    }
  }, [ t, rules, extraValidation ]);
}

/** 
 * 取得基本request方法。若displayLoading為true，則會在起始與結束時顯示loading畫面。
 * request若得到server的400 error，則會自動顯示對應msg欄位的錯誤訊息。
 */
export function useRequest(this: void, displayLoading = true) {
  const { t, i18n } = useTranslation();
  const basicConfig = useBasicApiConfig(i18n.language);
  const notifyError = useNotify('error');
  const logoutUser = useLogoutUser();
  const navigate = useNavigate();
  const { addTask, resolveTask } = useLoadingAction();

  const handleAxiosError = React.useCallback((axiosResponse: AxiosError) => {
    switch (axiosResponse.response.status) {
      case 400: {
        const responseData = axiosResponse.response.data as RESTfulResponse<undefined>;
        notifyError(t(`apiError.${responseData.msg}`));
        break;
      }
      case 401: {
        notifyError(t('apiError.error401'));
        logoutUser();
        navigate('Login');
        break;
      }
      case 403: {
        notifyError(t('apiError.error403'));
        break;
      }
      default: {
        notifyError(t('apiError.unexpectedServerError'));
        break;
      }
    }
  }, [ t, notifyError, logoutUser, navigate ]);

  return React.useCallback(function request<Data = unknown>(config: AxiosRequestConfig = {}) {
    const useConfig = Object.assign(basicConfig, config, {
      headers: Object.assign({}, basicConfig.headers, config.headers),
    });
    if (displayLoading) {
      addTask();
    }
    return new Promise<Data>((resolve) => {
      return axios
        .request<RESTfulResponse<Data>>(useConfig)
        .then((response: AxiosResponse<RESTfulResponse<Data>>) => {
          resolve(response.data.data);
        })
        .catch(handleAxiosError)
        .finally(() => {
          if (displayLoading) {
            resolveTask();
          }
        });
    });
  }, [ displayLoading, basicConfig, addTask, resolveTask, handleAxiosError ]);
}

/** 
 * 取得基本create方法。輸入RESTfulConfig，會自動根據baseUrl呼叫request方法。以RESTfulConfig的Schema限制輸入值。
 */
export function useCreate<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const { baseUrl } = config;

  return React.useCallback((data: Schema, axiosConfig: AxiosRequestConfig = {}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...createData } = data;
    const useConfig = Object.assign({
      url: baseUrl,
      data: createData,
      method: 'post',
    }, axiosConfig);

    return request<Schema>(useConfig);
  }, [ request, baseUrl ]);
}

/** 
 * 取得基本readOne方法。輸入RESTfulConfig，會自動根據baseUrl與id呼叫request方法、自動帶入t參數避免瀏覽器自動cache、自動呼叫RESTfulConfig的parser方法轉譯server回傳值。可傳入setter以縮簡code。
 */
export function useReadOne<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const { baseUrl, parser } = config;

  return React.useCallback((id: Schema['id'], axiosConfig: AxiosRequestConfig = {}, setter?: React.Dispatch<React.SetStateAction<Schema>>) => {
    const useConfig = Object.assign({
      url: `${baseUrl}/${id}`,
      method: 'get',
    }, axiosConfig, {
      params: Object.assign({}, {
        t: Date.now(),
      }, axiosConfig.params),
    });

    return request<Schema>(useConfig).then((restfulResponse) => {
      const data = parser(restfulResponse);

      if (setter) {
        setter(data);
      }
      return data;
    });
  }, [ request, parser, baseUrl ]);
}

/** 
 * 取得基本readall方法。輸入RESTfulConfig，會自動根據baseUrl呼叫request方法、自動帶入t參數避免瀏覽器自動cache、自動呼叫RESTfulConfig的parser方法轉譯server回傳值。可傳入setter以縮簡code。
 */
export function useReadAll<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const { baseUrl, parser } = config;

  return React.useCallback((axiosConfig: AxiosRequestConfig = {}, setter?: React.Dispatch<React.SetStateAction<Schema[]>>) => {
    const useConfig = Object.assign({
      url: baseUrl,
      method: 'get',
    }, axiosConfig, {
      params: Object.assign({}, {
        t: Date.now(),
      }, axiosConfig.params),
    });

    return request<Schema[]>(useConfig).then((restfulResponse) => {
      const dataList = restfulResponse.map((data) => {
        return parser(data);
      });

      if (setter) {
        setter(dataList);
      }

      return dataList;
    });
  }, [ request, baseUrl, parser ]);
}

/** 
 * 取得基本readAll方法。輸入RESTfulConfig，會自動根據baseUrl呼叫request方法、自動帶入t參數避免瀏覽器自動cache、自動呼叫RESTfulConfig的parser方法轉譯server回傳值。可傳入setter以縮簡code。
 */
export function useReadRecords<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const { baseUrl, parser } = config;

  return React.useCallback((axiosConfig: AxiosRequestConfig = {}, setter?: React.Dispatch<React.SetStateAction<RecordsData<Schema>>>) => {
    const useConfig = Object.assign({
      url: baseUrl,
      method: 'get',
    }, axiosConfig, {
      params: Object.assign({}, {
        page: 0,
        limit: 10,
        sort: '',
        orderby: '',
        t: Date.now(),
      }, axiosConfig.params),
    });

    return request<RecordsData<Schema>>(useConfig).then((restfulResponseData) => {
      const data = {
        ...restfulResponseData,
        records: restfulResponseData.records.map((data) => {
          return parser(data);
        }),
      };

      if (setter) {
        setter(restfulResponseData);
      }
      return data;
    });
  }, [ request, baseUrl, parser ]);
}

/** 
 * 根據RESTful資源的baseUrl，自動計算得出「標籤系統」對應標籤type的方法。
 */
export function convertBaseUrlToType(baseUrl: string) {
  return baseUrl.slice(baseUrl.lastIndexOf('/') + 1);
}

/** 
 * 取得基本readTags方法，此方法可載入目標RESTful資源的當前所有標籤。
 */
export function useReadTags<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const baseUrl = config.baseUrl;

  return React.useCallback((axiosConfig: AxiosRequestConfig = {}, setter?: React.Dispatch<React.SetStateAction<string[]>>) => {
    const type = convertBaseUrlToType(baseUrl);
    const useConfig = Object.assign({
      url: '/tags/keys',
      method: 'get',
    }, axiosConfig, {
      params: Object.assign({}, {
        type,
        t: Date.now(),
      }, axiosConfig.params),
    });

    return request<string[]>(useConfig).then((responseTagList) => {
      if (setter) {
        setter(responseTagList);
      }
      return responseTagList;
    });
  }, [ request, baseUrl ]);
}

/** 
 * 取得基本readTagData方法，此方法可載入目標RESTful資源指定id項目的當前所有標籤內容。
 */
export function useReadTagData<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const baseUrl = config.baseUrl;

  return React.useCallback((idList: string[], axiosConfig: AxiosRequestConfig = {}, setter?: React.Dispatch<React.SetStateAction<Record<string, string>[]>>) => {
    const type = convertBaseUrlToType(baseUrl);
    const useConfig = Object.assign({
      url: '/tags/resid',
      method: 'get',
    }, axiosConfig, {
      params: Object.assign({}, {
        t: Date.now(),
        type,
        res_id: idList.join(','),
      }, axiosConfig.params),
    });

    return request<Record<string, string>[]>(useConfig).then((responseDataList) => {
      const parsedDataList = responseDataList?.map((data) => {
        return Object.assign({}, data, {
          id: String(data.id),
        });
      }) || [];
      if (setter) {
        setter(parsedDataList);
      }
      return parsedDataList;
    });
  }, [ request, baseUrl ]);
}

/** 
 * 取得基本update方法，傳入代有id的資源，自動以PATCH方式呼叫request方法。
 */
export function useUpdate<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const { baseUrl } = config;

  return React.useCallback((data: Partial<Schema>, axiosConfig: AxiosRequestConfig = {}) => {
    const { id, ...updateData } = data;
    if (!id) {
      throw new Error('Update method need a data with id property!');
    }
    const useConfig = Object.assign({
      url: `${baseUrl}/${id}`,
      data: updateData,
      method: 'patch',
    }, axiosConfig);

    return request<Schema>(useConfig);
  }, [ request, baseUrl ]);
}

/** 
 * 取得基本destroy方法，傳入代有id的資源，自動以DELETE方式呼叫request方法。
 */
export function useDestroy<Schema extends BasicSchema>(this: void, config: RESTfulConfig<Schema>, displayLoading = true) {
  const request = useRequest(displayLoading);
  const baseUrl = config.baseUrl;

  return React.useCallback((id: Schema['id'], axiosConfig: AxiosRequestConfig = {}) => {
    const useConfig = Object.assign({
      url: `${baseUrl}/${id}`,
      method: 'delete',
    }, axiosConfig);

    return request(useConfig);
  }, [ request, baseUrl ]);
}

/**
 * 下載檔案
 */
export function useDownload(this: void, displayLoading = true) {
  const { t, i18n } = useTranslation();
  const basicConfig = useBasicApiConfig(i18n.language);
  const notifyError = useNotify('error');
  const { addTask, resolveTask } = useLoadingAction();

  const handleAxiosError = React.useCallback((axiosResponse: AxiosError) => {
    switch (axiosResponse.response.status) {
      case 400: {
        const responseData = axiosResponse.response.data as RESTfulResponse<undefined>;
        notifyError(t(`apiError.${responseData.msg}`));
        break;
      }
      case 401: {
        notifyError(t('apiError.error401'));
        break;
      }
      case 403: {
        notifyError(t('apiError.error403'));
        break;
      }
      default: {
        notifyError(t('apiError.unexpectedServerError'));
        break;
      }
    }
  }, [ t, notifyError ]);

  return React.useCallback(function downloadApi(config: AxiosRequestConfig = {}) {
    const useConfig = Object.assign(basicConfig, config, {
      headers: Object.assign({}, basicConfig.headers, config.headers),
      responseType: 'blob',
    });
    if (displayLoading) {
      addTask();
    }
    return axios
      .request<Blob>(useConfig)
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'log.csv');
        a.click();
      })
      .catch(handleAxiosError)
      .finally(() => {
        if (displayLoading) {
          resolveTask();
        }
      });
  }, [ displayLoading, basicConfig, addTask, resolveTask, handleAxiosError ]);
}

interface ThrottleActionCache<T = unknown> {
  cacheTime: number,
  promise: null | Promise<T>,
}
const throttleActionResouceMap = new Map<symbol, Map<string, ThrottleActionCache>>();
/** 
 * 對一個api呼叫進行throttle處理，以避免React component在短時間內多次建立時造成的多次React.useEffect呼叫。
 */
export function useThrottle<T = unknown>(symbol: symbol, action: (id: string) => Promise<T>, cacheTime = 200) {
  return React.useCallback(function wrapperThrottleAction(id = '') {
    const now = Date.now();
    const throttleActionResouce = throttleActionResouceMap.get(symbol) || new Map<string, ThrottleActionCache>();
    const throttleActionCache = throttleActionResouce.get(id);
    if (throttleActionCache && now - throttleActionCache.cacheTime < cacheTime) {
      return throttleActionCache.promise;
    }

    const promise = action(id);
    const newThrottleActionCache: ThrottleActionCache<T> = {
      cacheTime: now,
      promise,
    };
    throttleActionResouce.set(id, newThrottleActionCache);
    throttleActionResouceMap.set(symbol, throttleActionResouce);

    return promise;
  }, [ symbol, action, cacheTime ]);
}
/** 檢查throttle資源是否處於cache狀態 */
export function isThrottleReady(symbol: symbol, id = '', cacheTime = 200) {
  const throttleActionResouce = throttleActionResouceMap.get(symbol);
  if (throttleActionResouce) {
    const throttleActionCache = throttleActionResouce.get(id);
    if (throttleActionCache) {
      const now = Date.now();

      return now - throttleActionCache.cacheTime < cacheTime;
    }
  }

  return false;
}

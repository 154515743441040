import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoRouteHref } from 'src/hooks/useNavigate';
import {
  Unstable_Grid2 as Grid,
  Button,
} from '@mui/material';

export function CookieApprove() {
  const { t } = useTranslation();
  
  const [ isDiaplay, setIsDiaplay ] = React.useState(!localStorage.getItem('cookieApprove'));

  const onDismiss = React.useCallback((e: React.SyntheticEvent<HTMLButtonElement>) => {
    const result = e.currentTarget.dataset.result;
    localStorage.setItem('cookieApprove', result);
    setIsDiaplay(false);
  }, [ setIsDiaplay ]);
  const cookieHref = useMemoRouteHref('CookiePolicy');

  if (!isDiaplay) {
    return null;
  }

  return (
    <div className="cookieApprove">
      <Grid
        spacing={ 1 }
        container>
        <Grid xs>
          <p>
            { t('portal.footer.cookieText1') }
            <a href={ cookieHref }>
              { t('portal.nav.cookiePolicy') }
            </a>
            { t('portal.footer.cookieText2') }
          </p>
        </Grid>
        <Grid xs="auto">
          <Button
            data-result="true"
            onClick={ onDismiss }>
            { t('portal.footer.agree') }
          </Button>
          <Button
            data-result="false"
            onClick={ onDismiss }>
            { t('portal.footer.reject') }
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
export default CookieApprove;
